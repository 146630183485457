import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

const WaiterWiseSale = ({WaiterSale}) => {
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);

  useEffect(() => {
    if (WaiterSale) {
      setXaxis(WaiterSale.map((e) => e?.user?.name));
      setYaxis(WaiterSale.map((e) => Math.floor(Number(e.billTotal.toFixed(2)))));
    }
  }, [WaiterSale]);

  console.log(yaxis, xaxis);
  
    const options = {
        series: [
          {
            name: "Waiter wise Sales",
            data: yaxis,
          },
        ],
        chart: {
          height: 273,
          type: "area",
          zoom: {
            enabled: false,
          },
        },
        colors: ["#FF9F43"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        xaxis: {
          categories: xaxis,
          label:"Waiter"
        },
        yaxis: {
          label:"Bill Total"
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      };
      
  return (
    <div>
      <Chart
                    options={options}
                    series={options.series}
                    type="area"
                    height={273}
                  />
    </div>
  )
}

export default WaiterWiseSale
