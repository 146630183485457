import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { DiscountClass } from "../../../core/json/Discount";
import { editDiscount, addDiscount } from "../../../services/MasterApiServices";
import { useSelector } from "react-redux";

const DiscountModal = ({ mode, data, handleClose, handleRefresh }) => {
  const { register, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  function resetmodal() {
    if (mode === "edit" && data) {
      setValue("name", data?.name);
      setValue("percentage", data?.percentage);
    } else {
      reset();
    }
  }

  useEffect(() => {
    resetmodal();
  }, [data, mode, setValue]);

  // Handle form submission for adding or editing a discount
  const handleFormSubmit = async (formData) => {
    const objdiscount = new DiscountClass();
    objdiscount.name = formData?.name;
    objdiscount.percentage = formData?.percentage;
    objdiscount.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}";
    if (mode === "edit") {
      objdiscount.guid = data?.guid;
      objdiscount.id = data?.id;
    }

    try {
      const response =
        mode === "edit"
          ? await editDiscount(objdiscount)
          : await addDiscount(objdiscount);

      if (response) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      }else if(response==false){
        toast.error("false")
       }
    } catch (error) {
      toast.error(error.response.data.Message || "Something went wrong");
      console.error("Error handling :", error);
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {mode === "edit" ? "Edit Discount" : "Add Discount"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Percentage<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Percentage"
                            {...register("percentage")}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode === "edit" ? "Save Changes" : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountModal;
