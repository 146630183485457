import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {CounterSection} from '../../../core/json/CounterSection'
// function AddCounterSection() {
    const AddCounterSection = ({data}) => {
        const { register, handleSubmit, setValue, reset } = useForm({
          mode: "onChange",
          reValidateMode: "onChange",
        });
      
        // Get user from redux
        const { userId } = useSelector((state) => state.userauth);
        const { sectionList } = useSelector((state) => state.usersetting);
        const closeButtonRef = useRef();

        const closemodal = () => {
          if (closeButtonRef.current) {
            closeButtonRef.current.click();
          }
        };
        
      function resetmodal(){
       
      }
        useEffect(() => {
          resetmodal();
        }, [setValue]);
      
        // Handle form submission for adding or editing a modifier category
        const handleFormSubmit = async (formData) => {
            const objcountersection = new CounterSection();
            objcountersection.section = sectionList;
            objcountersection.counter= data;
            objcountersection.index=parseFloat(formData.index)
            objcountersection.updatedUser = `{${userId?.id}}`;
      
        
        };


  return (
    <div>
        <div
        className="modal fade"
        id="add-countersection"
        
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    
                    <h4>COUNTER SECTION ({data?.name})</h4>
                    
                  </div>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    data-bs-dismiss="modal" 
                    onClick={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                        <label>Select Sections<span className="text-danger">*</span></label>
                        <select
                  {...register("section",{ required: "section is required" })}
 
                  className=" form-control"
               >
                  <option value="" >
                    select section
                  </option>
                  {sectionList.map((unit) => (
                    <option key={unit.guid} value={unit.guid}>
                      {unit.name}
                    </option>
                  ))}
                </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCounterSection