import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
  Calendar,
  Layers,
  User,
  Smile
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import Table from "../../core/pagination/datatable";
import {
  deleteSales,
  getSalesByTransType,
} from "../../services/SalesApiServices";
import SalesQuotationModal from "../../components/transactions/modal/SalesQuotationModal";
import { InvSales } from "../../core/json/invSales";
import { DatePicker, Tabs } from "antd";
import Select from "react-select";
import { fetchCustomer, fetchProduct, fetchSettings } from "../../redux/userSetting";
const SalesQuotation = () => {
  const { TabPane } = Tabs;
  const [searchText, setSearchText] = useState("");
  const [salesData, setsalesData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const MySwal = withReactContent(Swal);
  const { productList,customerList,formatedproductList,formatedcustomerList} = useSelector(
    (state) => state.usersetting
  );

  const renderTooltip = (message) => (
    <Tooltip id="pdf-tooltip">{message}</Tooltip>
  );
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  useEffect(() => {
    dispatch(fetchProduct(branch));
    dispatch(fetchCustomer(branch))
    dispatch(fetchSettings(branch));
    handlesalesData();
  }, [branch]);
  const handlesalesData = async () => {
    try {
      const response = await getSalesByTransType(branch, 0);

      setsalesData(Array.isArray(response.data) ? response.data : []);
      console.log(response.data,"g");
      
    } catch (error) {
      console.error(
        "Failed to fetch sales invoices",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };
  
  const filteredData = salesData
    .filter((entry) => {
      return Object.keys(entry).some((key) => {
        return String(entry[key])
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    })
    .sort((a, b) => b.createdAt - a.createdAt);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleEditClick = async (guid) => {
    try {
     
      const response = salesData.find((sales) => sales.guid === guid);
      setUpdateData(response);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching sales invoice by GUID",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };


  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objsales = new InvSales();
          objsales.id = obj.id;
          objsales.guid = obj.guid;
          objsales.branch = branch;
          objsales.isDeleted = true;
          objsales.updatedUser = `{${userId?.id}}`;
          objsales.currency = "00000000-0000-0000-0000-000000000000";
          const response = await deleteSales(objsales);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The sales quotation has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlesalesData();
              setSearchText("");
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting sales quotation:", error);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "invdate",
      sorter: (a, b) => (dayjs(a.invdate).isAfter(dayjs(b.invdate)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Quotation",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },
    {
      title: "Remarks",
      dataIndex: "terms",
      sorter: (a, b) => a.refNo.length - b.refNo.length,
    },
    {
      title: "Status",
      dataIndex: "salesPaymentType",
      render: (salesPaymentType) => {
        let badgeClass = "";
        let text = "";

        switch (salesPaymentType) {
          case 0:
            text = "Cash";
            badgeClass = "bg-lightyellow";
            break;
          case 1:
            text = "Credit";
            badgeClass = "bg-lightred";
            break;
          case 2:
            text = "UPI";
            badgeClass = "bg-lightgreen";
            break;
          case 3:
            text = "Card";
            badgeClass = "bg-lightpurple";
            break;
          default:
            text = "Unknown";
            badgeClass = "";
        }

        return <span className={`badges ${badgeClass}`}>{text}</span>;
      },
      sorter: (a, b) => a.salesPaymentType.length - b.salesPaymentType.length,
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      sorter: (a, b) =>
        dayjs(a.deliveryDate).isAfter(dayjs(b.deliveryDate)) ? 1 : -1,
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Net Amount",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal.length - b.netTotal.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2" to="#">
              <i data-feather="eye" className="feather-eye"></i>
            </Link>
            {/* Edit Button */}
            <Link
              className="me-2 p-2"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* Delete Button */}
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  const reportcolumns = [
    {
      title: "Date",
      dataIndex: "invdate",
      sorter: (a, b) => (dayjs(a.invdate).isAfter(dayjs(b.invdate)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Quotation",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },
    {
      title: "Product",
      sorter: (a, b) => a.customerName.length - b.customerName.length,
      render: (_, record) => record?.customer?.name,
    },
    {
      title: "Customer",
      sorter: (a, b) => a.customerName.length - b.customerName.length,
      render: (_, record) => record?.customer?.name,
    },
   
    {
      title: "Net Amount",
      dataIndex: "netTotal",
    },
    {
      title: "Tax",
      dataIndex: "taxTotal",
      
    },
    {
      title: "Discount",
      dataIndex: "discountTotal",
      
    },
    {
      title: "Card Total",
      dataIndex: "card",
      
    },
    {
      title: "Cash Total",
      dataIndex: "cash",
      
    },
    
  ];

  const handleTabChange = (key) => {
    console.log('Active Tab:', key); 
    if(key==1){
      setIsFilterVisible(false)
    }
    else{
      setIsFilterVisible(true)
    }
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sales Quotation</h4>
                <h6>Manage sales here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handlesalesData()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add");
                  setModalOpen(true);
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
               
                      <label>From Date</label>
                      <div className="input-groupicon calender-input">
                      <Calendar className="info-img" />
                        <DatePicker
                        className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          // selected={fromDate}
                          // value={fromDate}
                          // onChange={handleFromDateChange}
                          // startDate={fromDate}
                          selectsStart
                          // dateFormat="dd/MM/yyyy"
                          
                        />
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon calender-input">
                      <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          // value={toDate}
                          // onChange={handleToDateChange}
                          // minDate={fromDate}
                          // startDate={fromDate}
                          selectsEnd
                          // disabled={!fromDate} 
                          // dateFormat="dd/MM/yyyy"
                          // className='form-control'
                          
                        />
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
               
                      <label>Products</label>
                      <div className="input-groupicon calender-input">
                      <Layers className="info-img" />
                      <Select
                          className="img-select"
                          options={formatedproductList}
                          // value={paymenttype}
                          // onChange={setPaymenttype}
                          classNamePrefix="react-select"
                          placeholder="Products"
                        />
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
               
                      <label>Customers</label>
                      <div className="input-groupicon calender-input">
                      <Smile className="info-img" />
                      <Select
                          className="img-select"
                          options={formatedcustomerList}
                          // value={paymenttype}
                          // onChange={setPaymenttype}
                          classNamePrefix="react-select"
                          placeholder="Customers"
                        />
                      </div>
                    </div>
                    </div>
                   
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link
                          className="btn btn-filters ms-auto"
                          // onClick={ ()=>{
                          //   setCurrentPage(1);
                          //   setPageSize(20);
                          //   handlegetsalesReport(currentPage,pageSize);}}
                        >
                          <i data-feather="search" className="feather-search" />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                </div>
              </div>
              <div className="table-responsive">
              <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Sales Quatation" key="1">
            
                <Table columns={columns} dataSource={filteredData} />
                  </TabPane>
                  <TabPane tab="Reports" key="2">
                <Table columns={reportcolumns} dataSource={filteredData} />
                    {/* <Table columns={summaryColumns} dataSource={summaryData} /> */}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reason Modal */}
      {modalOpen && (
        <SalesQuotationModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handlesalesData();
            setSearchText("");
          }}
        />
      )}
    </div>
  );
};

export default SalesQuotation;
