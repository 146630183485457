import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import {
  deleteSales,
  getSalesByTransType,
} from "../../services/SalesApiServices";
import SalesInvoiceModal from "../../components/transactions/modal/SalesInvoiceModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import { InvSales } from "../../core/json/invSales";
import dayjs from "dayjs";

const SalesInvoice = () => {
  const [searchText, setSearchText] = useState("");
  const [salesData, setsalesData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const MySwal = withReactContent(Swal);

  const { togglehead } = useSelector((state) => state.headerToggle);
  const renderTooltip = (message) => (
    <Tooltip id="pdf-tooltip">{message}</Tooltip>
  );
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  useEffect(() => {
    handlesalesData();
  }, [branch]);
  const handlesalesData = async () => {
    try {
      const response = await getSalesByTransType(branch, 2);
      console.log("inside handlesalesdata");
      console.log(response.data, "---------response");
      setsalesData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error(
        "Failed to fetch sales invoices",
        error.response.data.Message || "something went wrong"
      );
    }
  };

  const filteredData = salesData
    .filter((entry) => {
      return Object.keys(entry).some((key) => {
        return String(entry[key])
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    })
    .sort((a, b) => b.createdAt - a.createdAt);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleEditClick = async (guid) => {
    try {
      console.log("inside edit click");
      console.log(guid, "guid");

      const response = getsalesInvoiceByGuid(guid);
      console.log(response, "response.data");
      setUpdateData(response);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching sales invoice by GUID",
        error.response.data.Message || "something went wrong"
      );
    }
  };

  function getsalesInvoiceByGuid(guid) {
    console.log(updateData, "updated data");

    const filteredsales = salesData.filter((sales) => sales.guid === guid);
    console.log(filteredsales[0], "filteredsales[0]");
    return filteredsales[0];
  }
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objsales = new InvSales();
          objsales.id = obj.id;
          objsales.guid = obj.guid;
          objsales.branch = branch;
          objsales.isDeleted = true;
          objsales.updatedUser = `{${userId?.id}}`;
          objsales.currency = "00000000-0000-0000-0000-000000000000";

          const response = await deleteSales(objsales);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The sales invoice has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlesalesData();
              setSearchText("");
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "something went wrong",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting reason:",
            error.response?.data?.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "invdate",
      sorter: (a, b) => (dayjs(a.invdate).isAfter(dayjs(b.invdate)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Invoice#",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entryNumber.length - b.entryNumber.length,
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      sorter: (a, b) => a.customerName.length - b.customerName.length,
    },
    {
      title: "Reference No",
      dataIndex: "refNo",
      sorter: (a, b) => a.refNo.length - b.refNo.length,
    },
    {
      title: "Status",
      dataIndex: "salesPaymentType",
      render: (salesPaymentType) => {
        let badgeClass = "";
        let text = "";

        switch (salesPaymentType) {
          case 0:
            text = "Cash";
            badgeClass = "bg-lightyellow";
            break;
          case 1:
            text = "Credit";
            badgeClass = "bg-lightred";
            break;
          case 2:
            text = "UPI";
            badgeClass = "bg-lightgreen";
            break;
          case 3:
            text = "Card";
            badgeClass = "bg-lightpurple";
            break;
          default:
            text = "Unknown";
            badgeClass = "";
        }

        return <span className={`badges ${badgeClass}`}>{text}</span>;
      },
      sorter: (a, b) => a.salesPaymentType.length - b.salesPaymentType.length,
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate", //no dudate
      sorter: (a, b) =>
        dayjs(a.deliveryDate).isAfter(dayjs(b.deliveryDate)) ? 1 : -1,
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Net Amount",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal.length - b.netTotal.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2" to="#">
              <i data-feather="eye" className="feather-eye"></i>
            </Link>
            {/* Edit Button */}
            <Link
              className="me-2 p-2"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* Delete Button */}
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sales Invoice</h4>
                <h6>Manage sales here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handlesalesData()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add");
                  setModalOpen(true);
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reason Modal */}
      {modalOpen && (
        <SalesInvoiceModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handlesalesData();
            setSearchText("");
          }}
          modalOpen={modalOpen}
        />
      )}
    </div>
  );
};

export default SalesInvoice;
