import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import {
  deletePurchase,
  getPurchaseByTransType,
} from "../../services/PurchaseApiService";
import PurchaseInvoiceModal from "../../components/transactions/modal/PurchaseInvoiceModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import { PurchaseClass } from "../../core/json/Purchase";
import dayjs from "dayjs";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";

const PurchaseInvoice = () => {
  const [searchText, setSearchText] = useState("");
  const [purchaseData, setPurchaseData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const dispatch = useDispatch();

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  // Get branch from redux
  const { branch } = useSelector((state) => state.branchlist);

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  useEffect(() => {
    handlePurchaseData();
  }, [branch]);

  // Fetch purchase invoices data
  const handlePurchaseData = async () => {
    try {
      const response = await getPurchaseByTransType(branch, 2);
      setPurchaseData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Failed to fetch purchase invoices", error);
    }
  };

  const filteredData = purchaseData
    .filter((entry) => {
      return Object.keys(entry).some((key) => {
        return String(entry[key])
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    })
    .sort((a, b) => b.createdAt - a.createdAt); // Ensure new items appear on top

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Entry No",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entryNumber.length - b.entryNumber.length,
    },
    {
      title: "Supplier",
      dataIndex: "supplier.name",
      sorter: (a, b) => a.supplier.length - b.supplier.length,
      render: (_, record) => record?.supplier?.name,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNo",
      sorter: (a, b) => a.invoiceNo.length - b.invoiceNo.length,
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      sorter: (a, b) =>
        dayjs(a.invoiceDate).isAfter(dayjs(b.invoiceDate)) ? 1 : -1,
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Status",
      dataIndex: "purchasePaymentType",
      render: (purchasePaymentType) => {
        let badgeClass = "";
        let text = "";

        switch (purchasePaymentType) {
          case 0:
            text = "Cash";
            badgeClass = "bg-lightyellow";
            break;
          case 1:
            text = "Card";
            badgeClass = "bg-lightpurple";
            break;
          case 2:
            text = "Bank";
            badgeClass = "bg-lightblue";
            break;
          case 3:
            text = "Credit";
            badgeClass = "bg-lightred";
            break;
          case 4:
            text = "UPI";
            badgeClass = "bg-lightgreen";
            break;
          default:
            text = "Unknown";
            badgeClass = "";
        }

        return <span className={`badges ${badgeClass}`}>{text}</span>;
      },
      sorter: (a, b) =>
        a.purchasePaymentType.length - b.purchasePaymentType.length,
    },
    {
      title: "Due Date",
      dataIndex: "duedate",
      sorter: (a, b) => (dayjs(a.duedate).isAfter(dayjs(b.duedate)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Net Amount",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal.length - b.netTotal.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2" to="#">
              <i data-feather="eye" className="feather-eye"></i>
            </Link>
            {/* Edit Button */}
            <Link
              className="me-2 p-2"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* Delete Button */}
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);

  // Handle the click event to delete a Purchase Invoice
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objPurchase = new PurchaseClass();
          objPurchase.id = obj.id;
          objPurchase.guid = obj.guid;
          objPurchase.branch = branch;
          objPurchase.isDeleted = true;
          objPurchase.updatedUser = `{${userId?.id}}`;
          const response = await deletePurchase(objPurchase);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The Invoice has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlePurchaseData(); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting reason:", error);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handleEditClick = async (guid) => {
    try {
      console.log("inside edit click");
      console.log(guid, "guid");

      const response = getPurchaseInvoiceByGuid(guid);
      console.log(response, "response.data");
      setUpdateData(response);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching purchase invoice by GUID", error);
    }
  };

  function getPurchaseInvoiceByGuid(guid) {
    console.log(updateData, "updated data");

    const filteredPurchase = purchaseData.filter(
      (purchase) => purchase.guid === guid
    );
    console.log(filteredPurchase[0], "filteredPurchase[0]");
    return filteredPurchase[0];
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Purchase Invoice</h4>
                <h6>Manage purchase here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() => exportPDF(purchaseData, "Purchase Invoice")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() =>
                      exportExcel(purchaseData, "Purchase Invoice")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() =>
                      HandlePrint(purchaseData, "Purchase Invoice")
                    }
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handlePurchaseData()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add");
                  setModalOpen(true);
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredData} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen && (
        <PurchaseInvoiceModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handlePurchaseData(); // Refresh the list
            setSearchText(""); // Clear the search text
          }}
        />
      )}
    </div>
  );
};

export default PurchaseInvoice;
