import React from 'react'
import DashboardTable from './DashboardTable';

const DailySale = ({DailySales}) => {
  console.log(DailySales);
  const columns = [
    {
      title: "#",
      dataIndex: "index", // Typically used for row index
      key: "index",
      render: (text, record, index) => index + 1, // Render the row index
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        const parsedDate = new Date(record?.transactionDate);
        const day = String(parsedDate.getDate()).padStart(2, '0');
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = parsedDate.getFullYear();
        return `${day}/${month}/${year}`;
      }
    },
    
    {
      title: "Total",
      dataIndex: "billTotal",
      key: "billTotal",
      render: billTotal => billTotal.toFixed(2)
    },
  ];
  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="card flex-fill w-100 mb-4">
          <div className="card-body">
            <div className="table-responsive">
              {/* <table className="table table-borderless recent-transactions">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <div className="product-info">
                        <div className="info">
                          Lobar Handy
                        </div>
                      </div>
                    </td>
                    <td>$1,099.00</td>
                  </tr>
                </tbody>
              </table> */}
              <div className="table-responsive product-list">
                  <DashboardTable columns={columns} dataSource={DailySales} />
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DailySale
