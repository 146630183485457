import React, { useEffect, useState } from "react";
import Table from "../../../../core/pagination/datatable";
import { addNewProduct, getGroupEntryByGuid, getProductTypeguid, getTaxList, getUnitguid } from "../../../../services/MasterApiServices";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setproductDatasection } from "../../../../redux/productData";
import SectionWiseProductDetail from "../../../../core/json/SectionWiseProductDetail";
import { ProductClass } from "../../../../core/json/ProductClass";
import toast from "react-hot-toast";
import { TaxClass } from "../../../../core/json/TaxClass";


function AddProductSection({ register,getValues,watch,setValue}) {

  const [taxData, setTaxData] = useState([])

  const dispatch = useDispatch()
  const { branch } = useSelector((state) => state.branchlist);
  const { sectionList } = useSelector((state) => state.usersetting);
  const { product} = useSelector((state) => state.productdata);
 
  // Get user from redux
  const { userId } =useSelector((state)=>state.userauth)
 
  useEffect(() => {
    handleTaxList()
  }, [])
console.log(getValues().sectionWiseProductDetailList,"aaaaaaa",sectionList);

  const handleTaxList = async () => {
    try {
      const response = await getTaxList()
      setTaxData(response);
    } catch (error) {
      console.error("Error fetching suppliers", error);
    }
  };


  const columns = [
   
    {
      title: "Is Active",
      dataIndex: "",
      render: (_, value, index) => (
        <input type="checkbox" className=""
          {...register(`sectionWiseProductDetailList[${index}].IsActive`)} />
      )
    },
    {
      title: "Section",
      dataIndex: "name",
      render: (_, value, index) => (
        <div>
          <select name="" id="" className="form-control" 
            {...register(`sectionWiseProductDetailList[${index}].Section`)} >
            
                <option value={value.guid} key={value.id}>{value.name}</option>
             
          </select>
        </div>
      )
    },
    {
      title: "Price",
      dataIndex: "",
      render: (_, val, index) => (
        <div>
          <input type="number" className="form-control w-50"
            {...register(`sectionWiseProductDetailList[${index}].Price`)} />

        </div>
      )
    },

    {
      title: "Tax",
      dataIndex: "",

      render: (_, value, index) => (
        <div>
          <select name="" id="" className="form-control"
            {...register(`sectionWiseProductDetailList[${index}].Tax`)} 
            value={watch().sectionWiseProductDetailList[index].Tax}
            onChange={(e)=>setValue(`sectionWiseProductDetailList[${index}].Tax`, e.target.value)} >
            <option value=""> select</option>
            {taxData.map((obj) => {
              return (
                <option value={obj.guid} key={obj.id}>{obj.name}</option>
              )
            })}
          </select>
        </div>
      )

    },
    {
      title: "Tax Inclusive",
      dataIndex: "",
      render: (_, value, index) => (
        <input type="checkbox" className=""
          {...register(`sectionWiseProductDetailList[${index}].IsTaxIncludedInPrice`)} />
      )
    },

  ];
 
const handleaddSectionWiseProduct=async(formData)=>{
  const taxobj = new TaxClass()
  const sectionWiseProductDetailList = getValues()?.sectionWiseProductDetailList?.map(e=>({
    Section: sectionList?.find((item) => item.guid == e.Section),
    IsActive: e.IsActive,
    IsTaxIncludedInPrice: e.IsTaxIncludedInPrice,
    Price: Number(e.Price),
    tax:(taxData?.find((item) => item.guid == e.Tax))
      }))
      console.log(sectionWiseProductDetailList,"gggggggggggg",);
      
    dispatch(setproductDatasection(sectionWiseProductDetailList))
  const objsectionproduct = new SectionWiseProductDetail();
  objsectionproduct.ProductId = 0;
  objsectionproduct.Section = null;
  objsectionproduct.Tax = null;
  objsectionproduct.Price = 0;
  objsectionproduct.IsTaxIncludedInPrice = false;
  objsectionproduct.MasterId = "";
  objsectionproduct.Discount = null;
  objsectionproduct.IsActive = false;
 console.log(formData);
 const objproduct = new ProductClass();
    objproduct.code = product.code; 
     objproduct.name = product.name;
     objproduct.shortName = product.shortName; 
     objproduct.barCode = product.barCode; 
    //  objproduct.category = await getCategorybyGuid (product.category);
     objproduct.type = await getProductTypeguid (product.type); 
     objproduct.group = await getGroupEntryByGuid(product.group);
     objproduct.unit = await getUnitguid(product.unit);
     objproduct.cost = product.cost; 
     objproduct.basePrice =product.basePrice;
     objproduct.otherDescription = product.otherDescription;
     objproduct.sectionWiseProductDetailList = sectionWiseProductDetailList;
     objproduct.arabicDescription = formData.arabicDescription;
     objproduct.isRateVaryProduct = formData.isRateVaryProduct;
     objproduct.isDailyStockItem =formData.isDailyStockItem;
     objproduct.hsnCode =formData.hsnCode;
     objproduct.isMenuItem =formData.isMenuItem;
  
     objproduct.updatedUser =  `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}"; //userId
  
// if (mode === 'edit') {
// objproduct.guid = data.guid;
// objproduct.id = data.id;   
// }

try {
const response =  await addNewProduct(objproduct);
  console.log(objproduct);
  
  

if (response) {
  toast.success("Success");
  // handleRefresh(); // Refresh the list
  // handleClose(); // Close the modal
}
} catch (error) {
toast.error("Something went wrong");
console.error("Error handling :", error);
}
}

  return (
    <div>
   
        <div className="table-responsive product-list">
          <Table columns={columns} dataSource={sectionList} />
        </div>

  

    </div>
  )
}

export default AddProductSection