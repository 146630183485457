import React from 'react'
import under from '../../../core/img/under.png'

const Construction = () => {
  return (
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,paddingTop:"100px" }}>
  <img src={under} alt="" style={{ height: '450px' }} className='col-lg-4 col-md-6 col-12' />
</div>
  )
}

export default Construction
