import React from 'react'
import DashboardTable from './DashboardTable';

const ProductWiseSale = ({ProductSale}) => {
  const columns = [
    {
      title: "#",
      dataIndex: "index", // Typically used for row index
      key: "index",
      render: (text, record, index) => index + 1, // Render the row index
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        const parsedDate = new Date(record?.transactionDate);
        const day = String(parsedDate.getDate()).padStart(2, '0');
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = parsedDate.getFullYear();
        return `${day}/${month}/${year}`;
      }
    },
    {
      title: "Product",
      dataIndex: "product.name",
      key: "product",
      render: (_, record) => record.product.name // Access nested object
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Bill",
      dataIndex: "billTotal",
      key: "billTotal",
      render: billTotal => billTotal.toFixed(2)
    },
  ];
    return (
        <div className="section-wise-sale">
         
          <div className="d-flex justify-content-center">
            <div className="card flex-fill w-100 mb-4">
              <div className="card-body">
                <div className="table-responsive">
                  

                  <div className="table-responsive product-list">
                  <DashboardTable columns={columns} dataSource={ProductSale} />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default ProductWiseSale
