
import React, { useEffect, useRef, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  Archive,
  User,
  Calendar,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import { Table } from "antd";
import { purchaseslist } from "../../../core/json/purchaselistdata";
import { setheadertoggle } from "../../../redux/toggle";
import { useDispatch, useSelector } from "react-redux";
import { getSalesReport } from "../../../services/ReportApiService";
import { DatatablePagingParameter, Filterclass } from "../../../core/json/DatatablePagingParameters";
import {
  exportPDF,
  exportExcel,
  HandlePrint,
  renderTooltip,
} from "../../../redux/helper";
import * as XLSX from 'xlsx';
import { Checkbox } from "antd";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { DatePicker } from "antd";
// import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { fetchPaymentType} from "../../../redux/userSetting";
import { label } from "yet-another-react-lightbox";


const CardSales = () => {
  const purchasedata = purchaseslist;
  const [searchText, setSearchText] = useState("");
  const [salesData, setsalesData] = useState();
  const[cardType,setCardType] = useState();
  
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [totalcount, settotalcount] = useState();
  const [currentPage, setCurrentPage] = useState(1); 
  const [pageSize, setPageSize] = useState(20);
  const dispatch = useDispatch()
  const { branch } = useSelector((state) => state.branchlist);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const {paymentTypeList} = useSelector((state) => state.usersetting);
  const tableRef = useRef(null);
  const filteredData = purchasedata.filter((entry) => {
    return Object.keys(entry).some((key) => {
      return String(entry[key])
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  });
  useEffect(() => {
    handlegetsalesReport(currentPage,pageSize);
    
    dispatch(fetchPaymentType())
   
  }, [])
  console.log(cardType,"type");
  
  
  const handlegetsalesReport = async (page,size) => {
    const DatatablePagingParameters = new DatatablePagingParameter();
    DatatablePagingParameters.PageNumber = page;
    DatatablePagingParameters.PageSize = size;
    const formattedfromDate = dayjs(fromDate).format('YYYY-MM-DD');
    const formattedtoDate = dayjs(toDate).format('YYYY-MM-DD');
    const Filters = new Filterclass()
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
   
    Filters.PaymentCardType = cardType?.value;
    try {
      const response = await getSalesReport({ DatatablePagingParameters, Filters });
      console.log(response?.data);
      setsalesData(response?.data?.salesReportDetail)


    } catch (error) {
      console.error(
        "Error Fetching Account Type!",
        error.response.data.Message
      );
    }
  }
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const exportToPDF = () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const tableRef1 = document.getElementById('table-to-export');

    html2canvas(tableRef1).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 190; // Width in PDF
      const pageHeight = 295; // Height for A4
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
      let heightLeft = imgHeight;
      let position = 10;

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('Sales_Report.pdf');
    });
  };

  // const exportToExcel = () => {
  //   const wb = XLSX.utils.book_new();
  //   const ws = XLSX.utils.json_to_sheet(salesData);  // Convert sales data to sheet
  //   XLSX.utils.book_append_sheet(wb, ws, "SalesReport");
  //   XLSX.writeFile(wb, "Sales_Report.xlsx");
  // };
  const printTable = () => {
    window.print();
  };

  const menuItems = [
    { value: "1", label: "Transaction Date", checked: true },
    { value: "2", label: "Section", checked: true },
    { value: "3", label: "Bill No", checked: true },
    {value:"4",label:"Gross Total",checked:false},
    {value:"5",label:"Tax Total",checked:false},
    {value:"6",label:"Vat Total",checked:false},
    {value:"7",label:"Discount",checked:false},
    {value:"8",label:"Other Charges",checked:false},
    {value:"9",label:"User",checked:false},
    { value: "10", label: "Bill Total", checked: true },
    { value: "11", label: "Customer", checked: true },
    { value: "12", label: "Card", checked: true },
    { value: "14", label: "Cash", checked: true },
    { value: "15", label: "Card No", checked: true },
    { value: "16", label: "Card Type", checked: true },
    {value:"17",label:"Bill Date",checked:false},
    {value:"18",label:"Bill Time",checked:false},
    {value:"19",label:"Counter",checked:false},
    {value:"20",label:"Location",checked:false},
    {value:"21",label:"User",checked:false}
   ];

  const columns = [
    {
      title: "Transaction Date",
      key: "1",
      render: (text) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        };

        return (
          <span>
            {formatDate(text.transactionDate)}
          </span>
        );
      },

    },
    
    {
      title: "Section",
      key: "2",
      render: (text) => (
        <span

        >
          {text.section.name}
        </span>
      ),
    },

    {
      title: "Bill No",
      key: "3",
      dataIndex: "billNo",


    },
    {
      title: "Gross Total",
      key: "4",
      dataIndex: "grossTotal",


    },
    {
      title: "Tax Total",
      key: "5",
      dataIndex: "taxAmount",


    },
    {
      title: "Vat Total",
      key: "6",
      dataIndex: "vatAmount",


    },
    {
      title: "Discount",
      key: "7",
      dataIndex: "discount",


    },
    {
      title: "Other Charges",
      key: "8",
      dataIndex: "otherCharges",


    },
    {
      title: "Round Off",
      key: "9",
      dataIndex: "roundOff",
    },
    {
      title: "Bill Total",
      key: "10",
      dataIndex: "billTotal",

    },
    {
      title: "Customer",
      key: "11",
      render: (text) => (
        <span

        >
          {text.customer.name}
        </span>
      ),

    },
    {
      title: "Cash",
      key: "12",
      dataIndex:"cash"
    },
     {
          title: "Card",
          key: "13",
          dataIndex: "card",
    
        },

   
    {
      title: "Card No",
      key: "14",
     dataIndex:"cardNo"
    },
   
    {
      title: "Card Type",
      key: "15",
      render: (text) => (
        <span

        >
          {text.paymenCardType.cardName}
        </span>
      ),
    },
    
    {
      title: "Bill Date",
      key: "16",
      render: (text) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        };
       
        return (
          <span>
            {formatDate(text.billDate)}
          </span>
        );
      },

    },
    {
      title: "Bill Time",
      key: "17",
      dataIndex: "billTime",

    },
    {
      title: "Counter",
      key: "18",
      render: (text) => (
        <span

        >
          {text.counter.name}
        </span>
      ),

    },

    {
      title: "Location",
      key: "19",
      dataIndex: "location",

    },
    {
      title: "User",
      key: "20",
      render: (text) => (
        <span>
          {text.user.name}
        </span>
      ),
    },

  ];

  const [selectedColumns, setSelectedColumns] = useState();

  useEffect(() => {
    // Set default selected columns based on menuItems' checked status
    const initialSelectedColumns = menuItems
      .filter((item) => item.checked)
      .map((item) => columns.find((col) => col.key === item.value));

    setSelectedColumns(initialSelectedColumns);
  }, []);
  console.log(selectedColumns, "qqqqqq");

  const handleCheckboxChange = (columnKey, checked) => {
    if (checked) {
      // Add column if checkbox is checked
      setSelectedColumns(prevColumns => {
        const updatedColumns = [
          ...prevColumns,
          columns.find(col => col.key === columnKey)
        ];

        // Sort the array by col.key in ascending order
        return updatedColumns.sort((a, b) => (a.key > b.key ? 1 : -1));
      });
    } else {
      // Remove column if checkbox is unchecked
      setSelectedColumns(prevColumns =>
        prevColumns.filter(col => col.key !== columnKey)
      );
    }
  };

  

  
  const cardtype = paymentTypeList?.map(e=>({
    value:e.guid,
    label:e.cardName
  }))

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleFromDateChange = (date) => {
    console.log(date, 'from date');
    setFromDate(date);


    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
    console.log(date, 'to date');
  };


  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Card Sale Report</h4>

              </div>
            </div>

            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={exportToPDF}>
                    <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="pdf-icon" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("Excel")}>
                  <Link onClick={() => exportExcel(salesData, "sales")}>
                    <ImageWithBasePath src="assets/img/icons/excel.svg" alt="excel-icon" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("Print")}>
                  <Link onClick={printTable}>
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>

          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                      }`}
                    onClick={toggleFilterVisibility}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"

                    />
                    <span >
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>


                <div className="dropdown">
                  <button
                    className="btn  dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <Sliders className="info-img" />
                  </button>
                  <ul className="dropdown-menu">
                    {menuItems?.map((obj, i) => {
                      return (

                        <li key={i}>
                          <Checkbox
                            onChange={(e) => handleCheckboxChange(obj.value, e.target.checked)}
                            defaultChecked={obj.checked}
                          >
                            {obj.label}
                          </Checkbox>
                        </li>
                      )
                    })}

                  </ul>
                </div>

              </div>

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                      <label>From Date</label>
                      <div className="input-groupicon calender-input">
                      <Calendar className="info-img" />
                      <DatePicker
                        className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          // selected={fromDate}
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          // dateFormat="dd/MM/yyyy"
                          
                        />
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon calender-input">
                      <Calendar className="info-img" />
                      <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          // selected={toDate}
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate} // disable until from date is selected
                          // dateFormat="dd/MM/yyyy"
                          // className='form-control'
                          
                        />
                      </div>
                    </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i className="fas fa-money-bill info-img" />
                        <Select
                          className="img-select"
                          options={cardtype}
                          value={cardType}
                          onChange={setCardType}
                          classNamePrefix="react-select"
                          placeholder="Payment Type"
                        />
                      </div>
                    </div>
                   
                    
                    

                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto" onClick={ ()=>{
                            setCurrentPage(1);
                            setPageSize(20);
                            handlegetsalesReport(currentPage,pageSize);}}
                        >
                          
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              {
                selectedColumns &&

                <div className="table-responsive product-list">
                  <Table columns={selectedColumns} dataSource={salesData} ref={tableRef} id="table-to-export" 
                    pagination={{
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalcount} items`,
                    current: currentPage,
                    pageSize: pageSize,
                    total: totalcount, // Total records count
                    onChange: (page, size) => {
                      setCurrentPage(page);
                      setPageSize(size);
                      handlegetsalesReport(page,size)
                    },
                    showSizeChanger: true, // To enable page size selection dropdown
                    pageSizeOptions: ['5', '10', '20', '50'], // Different page size options
                  }} />

                </div>
              }
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};
export default CardSales;