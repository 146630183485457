import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronUp, Filter, RotateCcw } from "feather-icons-react";
import Table from "../../../core/pagination/datatable";
import { useEffect } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../../redux/helper";
import { DatePicker, Select, Tabs } from "antd";
import { getConsolidatedReportSectionwise } from "../../../services/ReportApiService";
import {
  DatatablePagingParameter,
  Filterclass,
} from "../../../core/json/DatatablePagingParameters";
import dayjs from "dayjs";
import { useMemo } from "react";

const SectionReport = () => {
  const [searchText, setSearchText] = useState("");
  const [reportData, setreportData] = useState([]);
  const [summaryReportData, setSummaryReportData] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const { branch } = useSelector((state) => state.branchlist);
  const dispatch = useDispatch();
  const { TabPane } = Tabs;

  const { togglehead } = useSelector((state) => state.headerToggle);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);

    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  useEffect(() => {
    //setreportData(salesReportConsol);
    handleReportList();
  }, []);
  const handleReportList = async () => {
    const DatatablePagingParameters = new DatatablePagingParameter();
    const formattedfromDate = dayjs(fromDate).add(1, "day");
    const formattedtoDate = dayjs(toDate).add(1, "day");
    const Filters = new Filterclass();
    DatatablePagingParameters.PageSize = -1;
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    Filters.PaymentType = "";
    Filters.Waiter = "";
    Filters.Counter = "";
    Filters.Section = "";
    try {
      const response = await getConsolidatedReportSectionwise({
        DatatablePagingParameters,
        Filters,
      });

      const dataArray = [response.data];

      if (Array.isArray(dataArray)) {
        const data = dataArray.map((item) => {
          return (item.salesReportConsol || []).map((e) => ({
            branch: e.branch?.name,
            section: e.section?.name,
            netTotal: e.netTotal,
          }));
        });
        setreportData(data[0]);
      } else {
        setreportData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "something went wrong");
      setreportData([]);
    }
  };
  const { branches, sections, tableData, summaryData, sectionGrandTotal } = useMemo(() => {
    // Extract unique branches and sections
    const branches = [...new Set(reportData.map((item) => item.branch))];
    const sections = [...new Set(reportData.map((item) => item.section))];

    // Preprocess reportData into a nested map for efficient lookup
    const dataMap = reportData.reduce((acc, item) => {
      if (!acc[item.branch]) acc[item.branch] = {};
      if (!acc[item.branch][item.section]) acc[item.branch][item.section] = 0;
      acc[item.branch][item.section] += item.netTotal;
      console.log(acc,"hmm");
      
      return acc;
    }, {});
  
    // Generate tableData
    const tableData = branches.map((branch) => {
      const row = { key: branch, branch };
      console.log('Processing branch:', branch);
  
      let branchTotal = 0;
  
      sections.forEach((section) => {
        const netTotal = dataMap[branch]?.[section] || 0;
        row[section] = netTotal;
        branchTotal += netTotal;
        console.log(`Branch: ${branch}, Section: ${section}, NetTotal: ${netTotal}, BranchTotal: ${branchTotal}`);
      });
  
      row.branchTotal = branchTotal;
      return row;
    });
  
    // Generate summaryData
    const summaryData = sections.map((section) => {
      const sectionTotal = reportData
        .filter((item) => item.section === section)
        .reduce((sum, item) => sum + item.netTotal, 0);
  
      return { key: section, section, sectionTotal };
    });
  
    // Calculate grand totals
    const sectionGrandTotal = summaryData.reduce((sum, row) => sum + row.sectionTotal, 0);
    const grandTotal = tableData.reduce((sum, row) => sum + row.branchTotal, 0);
  
    // Add total row to summaryData
    const totalRow = {
      key: 'total',
      section: 'Total',
      sectionTotal: sectionGrandTotal,
    };
    summaryData.push(totalRow);
  
    // Create the last row for tableData representing section totals
    const lastRow = { key: 'sectionTotal', branch: 'Section Totals' };
    sections.forEach((section) => {
      const totalForSection = summaryData.find((s) => s.section === section)?.sectionTotal || 0;
      lastRow[section] = totalForSection;
    });
    lastRow.branchTotal = grandTotal;
  
    // Include the last row in the table data
    tableData.push(lastRow);
  
    return { branches, sections, tableData, summaryData, sectionGrandTotal };
  }, [reportData]);
console.log(tableData,"table");

  const modifiedList = Array.isArray(tableData)
    ? tableData?.filter((value) =>
        value.branch?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];
  const columns = [
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (text, record) =>
        record.key === "sectionTotal" ? <strong>{text}</strong> : text,
    },
    ...sections.map((section) => ({
      title: section,
      dataIndex: section,
      key: section,
      render: (value, record) => (value !== undefined ? value : 0),
    })),
    {
      title: "Branch Total",
      dataIndex: "branchTotal",
      key: "branchTotal",
      render: (value, record) =>
        record.key === "sectionTotal" ? (
          <strong>{value}</strong>
        ) : value !== undefined ? (
          value
        ) : (
          0
        ),
    },
  ];

  const summaryColumns = [
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
    },
    {
      title: "Total",
      dataIndex: "sectionTotal",
      key: "sectionTotal",
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Section Consolidated Reports</h4>
                <h6>Manage consolidated section reports here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportPDF(reportData, "Discount List")}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(reportData, "Discount List")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() => HandlePrint(reportData, "Discount List")}
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleReportList()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    onClick={toggleFilterVisibility}
                    id="filter_search"
                  >
                    <Filter className="filter-icon" />
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>
              </div>

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>From Date</label>
                        <DatePicker
                          id="from-date"
                          placeholderText="From Date"
                          selected={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>To Date</label>
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate} // disable until from date is selected
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link
                          className="btn btn-filters ms-auto"
                          onClick={handleReportList}
                        >
                          <i data-feather="search" className="feather-search" />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Detailed View" key="1">
                    <Table columns={columns} dataSource={modifiedList} />
                  </TabPane>
                  <TabPane tab="Summary View" key="2">
                    <Table columns={summaryColumns} dataSource={summaryData} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SectionReport;
