
import { Tooltip } from "react-bootstrap"
import React, { useRef }  from 'react'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { useReactToPrint } from 'react-to-print'

  export function renderTooltip (message) 
  {
    return <Tooltip id="pdf-tooltip">{message}</Tooltip>
  }

  const removedProps = (obj, propsArray) => 
    {
      propsArray.forEach(prop => delete obj[prop]);
      return obj;
    }
    const propsToDelete = ["id",	"guid",	"version",	"isDeleted",	"createdUser",	"createdDate",	"updatedUser",	"updatedDate"];
    
  
    // PDF Export Function
    // export function exportPDF(datatoexport, exportfilename)
    // {

        
    //   const tableData = datatoexport.map(row => removedProps(row, propsToDelete));

    //   const doc = new jsPDF();
    //   doc.text(exportfilename, 10, 10);
    //   console.log(tableData);
      
    //   const header = Object.keys(tableData[0]);
    //   console.log(header);
      
    //   const objBody = tableData.map(row =>
    //     header.map(key => row[key])  // Map each value to the corresponding header key
    //   );
    //   console.log(objBody);
      
    //   autoTable(doc, {
    //     head: [header],  // Use the header array directly
    //     body: objBody,   // Use the aligned body data
    //   });
      
    //   doc.save(`${exportfilename}.pdf`);
    // }
    export function exportPDF(datatoexport, exportfilename) {
      if (!datatoexport || datatoexport.length === 0) {
        console.error("No data to export to PDF.");
        return;
      }
    
      const tableData = datatoexport.map(row => removedProps(row, propsToDelete));
    
      const doc = new jsPDF();
      doc.text(exportfilename, 10, 10);
    
      const header = Object.keys(tableData[0]);
      const objBody = tableData.map(row => header.map(key => row[key]));
    
      autoTable(doc, {
        head: [header],
        body: objBody,
      });
    
      doc.save(`${exportfilename}.pdf`);
    }
    // export const exportPDF = (data, title) => {
    //   const doc = new jsPDF();
    //   doc.text(title, 10, 10);
    //   data.forEach((item, index) => {
    //     doc.text(`${index + 1}. ${item.name} - ${item.shortName}`, 10, 20 + index * 10);
    //   });
    //   doc.save(`${title}.pdf`);
    // };
    // Excel Export Function
    // export function exportExcel(datatoexport, exportfilename) 
    // {
    //     const tableData = (datatoexport).map(row =>
    //     {
    //       return removedProps(row, propsToDelete);
    //     })

    //   const ws = XLSX.utils.json_to_sheet(tableData);
    //   const wb = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(wb, ws, exportfilename);
    //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    //   const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    //   saveAs(data, exportfilename + ".xlsx");
    // }
    export function exportExcel(datatoexport, exportfilename) {
      if (!datatoexport || datatoexport.length === 0) {
        console.error("No data to export to Excel.");
        return;
      }
    
      const tableData = datatoexport.map(row => removedProps(row, propsToDelete));
    
      const ws = XLSX.utils.json_to_sheet(tableData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, exportfilename);
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(data, `${exportfilename}.xlsx`);
    }
    // export const exportExcel = (data, fileName) => {
    //   const worksheet = XLSX.utils.json_to_sheet(data);
    //   const workbook = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(workbook, worksheet, "Counters");
    //   XLSX.writeFile(workbook, `${fileName}.xlsx`);
    // };
    
    
      // Print Functionality
  // export function HandlePrint(datatoPrint, printfilename)
  // { 
  //     const tableData = (datatoPrint).map(row =>
  //       {
  //         return removedProps(row, propsToDelete);
  //       })
  //     useReactToPrint({
  //     content: () => tableData,
  //     documentTitle: printfilename,
  //   })
  // }
  export function HandlePrint({ datatoPrint = [], printfilename = 'Document' }) {
    
  
    // Ensure datatoPrint is an array and handle cases where it might be null or undefined
    const tableData = (Array.isArray(datatoPrint) ? datatoPrint : []).map(row => {
      return removedProps(row, propsToDelete);
    });
  
     useReactToPrint({
      content: () => tableData,
      documentTitle: printfilename,
    });
  }
  // export const HandlePrint = (data, title) => {
  //   const printContent = data.map(item => `<div>${item.name} - ${item.shortName}</div>`).join("");
  //   const printWindow = window.open("", "", "width=800,height=600");
  //   printWindow.document.write(`<html><head><title>${title}</title></head><body>`);
  //   printWindow.document.write(printContent);
  //   printWindow.document.write("</body></html>");
  //   printWindow.document.close();
  //   printWindow.print();
  // };