import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { getStockByBranch, deleteStock } from "../../services/StockApiServices";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import dayjs from "dayjs";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import { getJSON } from "jquery";
import { InvStockAdjustment } from "../../core/json/invStockAdjust";
import StockAdjustmentModal from "../../components/transactions/modal/StockAdjustmentModal";

const StockAdjustment = () => {
  const [searchText, setSearchText] = useState("");
  const [stockData, setstockData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  const [transformedData, settransformedData] = useState([]);

  const { togglehead } = useSelector((state) => state.headerToggle);

  // Get branch from redux
  const { branch } = useSelector((state) => state.branchlist);
  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  const dispatch = useDispatch();
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  useEffect(() => {
    handlestockData();
  }, [branch]);

  // Fetch Stock adjustment data
  const handlestockData = async () => {
    try {
      console.log("response");
      const response = await getStockByBranch(branch);
      const stockData = Array.isArray(response.data) ? response.data : [];
      console.log(stockData, "stockdata");
      setstockData(stockData);
      // console.log(response?.data,"zzzzzzzz");
      console.log(stockData, "gggggggg");

      // const transformedData = (stockData) => {
      //   if (stockData.length === 0) {
      //     return [];
      //   }

      //   return stockData?.flatMap((stockAdjData) =>
      //     stockAdjData?.stockAdjustmentDetails?.map((detailData) => ({
      //       date: stockAdjData.date || dayjs().format("YYYY-MM-DD"),
      //       no: stockAdjData.no || 0,
      //       product: detailData.product?.name || "",
      //       type: detailData.type || 0,
      //       quantity: detailData.product?.quantity || 0,
      //       unit: detailData.product?.unit?.name
      //         ? detailData.product?.unit?.name
      //         : "",
      //       guid: stockAdjData.guid,
      //     }))
      //   );
      // };

      // console.log(transformedData, "transformedData");
      // settransformedData(transformedData);
    } catch (error) {
      console.error(
        "Failed to fetch Stock adjustments",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const filteredData = transformedData
    .filter((entry) => {
      return Object.keys(entry).some((key) => {
        return String(entry[key])
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    })
    .sort((a, b) => b.createdAt - a.createdAt);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Entry No",
      dataIndex: "no",
      sorter: (a, b) => a.entryNumber.length - b.entryNumber.length,
    },
    // {
    //   title: "Product",
    //   dataIndex: "stockAdjustmentDetails.product",
    //   sorter: (a, b) => a.product.length - b.product.length,
    // },
    {
      title: "Product",
      dataIndex: "stockAdjustmentDetails",
      render: (stockAdjustmentDetails) =>
        stockAdjustmentDetails[0]?.product?.name || "N/A", // Handle array and possible null values
      sorter: (a, b) => {
        const productA = a.stockAdjustmentDetails[0]?.product?.name || "";
        const productB = b.stockAdjustmentDetails[0]?.product?.name || "";
        return productA.localeCompare(productB); // Compare strings
      },
    },

    {
      title: "Type",
      dataIndex: "type",
      render: (type) => {
        let badgeClass = "";
        let text = "";

        switch (type) {
          case 0:
            text = "Stock Adjustment";
            badgeClass = "bg-lightyellow";
            break;
          case 1:
            text = "Damage";
            badgeClass = "bg-lightpurple";
            break;
          case 2:
            text = "Wastage";
            badgeClass = "bg-lightblue";
            break;
          case 3:
            text = "Credit";
            badgeClass = "bg-lightred";
            break;
          case 4:
            text = "UPI";
            badgeClass = "bg-lightgreen";
            break;
          default:
            text = "Unknown";
            badgeClass = "";
        }

        return <span className={`badges ${badgeClass}`}>{text}</span>;
      },
      sorter: (a, b) => a.type.length - b.type.length,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks.length - b.remarks.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {/* Edit Button */}
            <Link
              className="me-2 p-2"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* Delete Button */}
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);

  // Handle the click event to delete a Stock adjustments
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objStockAdj = new InvStockAdjustment();
          objStockAdj.id = obj.id;
          objStockAdj.guid = obj.guid;
          objStockAdj.branch = branch;
          objStockAdj.type = 0;
          // objStockAdj.isDeleted = true;
          objStockAdj.updatedUser = `${userId?.id}`;
          const response = await deleteStock(objStockAdj);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The Stock Adjustment has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlestockData();
              setSearchText("");
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting reason:",
            error?.response?.data?.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handleEditClick = async (guid) => {
    try {
      console.log("inside edit click");
      console.log(guid, "guid");

      const response = getStockAdjByGuid(guid);
      console.log(response, "response");
      setUpdateData(response);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching Stock adjustment by GUID",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  function getStockAdjByGuid(guid) {
    console.log(updateData, "updated data");

    const filteredStock = stockData.filter(
      (stockAdj) => stockAdj.guid === guid
    );
    console.log(filteredStock[0], "filteredStock[0]");
    return filteredStock[0];
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Stock Adjustment</h4>
                <h6>Manage Stock Adjustments here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() => exportPDF(stockData, "Stock Adjustment")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(stockData, "Stock Adjustment")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() => HandlePrint(stockData, "Stock Adjustment")}
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handlestockData()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add");
                  setModalOpen(true);
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={stockData} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen && (
        <StockAdjustmentModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handlestockData();
            setSearchText("");
          }}
        />
      )}
    </div>
  );
};

export default StockAdjustment;
