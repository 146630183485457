import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { SectionClass } from "../../../core/json/SectionClass";
import {
  addNewSection,
  editSection,
} from "../../../services/MasterApiServices";
import { useDispatch, useSelector } from "react-redux";
import { fetchSection } from "../../../redux/userSetting";

const SectionModal = ({ mode, data, handleClose, handleRefresh }) => {
  //get branch from redux
  const { branch } = useSelector((state) => state.branchlist);
const dispatch = useDispatch()
  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  const { register, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function resetmodal() {
    if (mode === "edit" && data) {
      setValue("name", data.name);
      setValue("shortName", data.shortName);
    } else {
      reset();
    }
  }

  useEffect(() => {
    resetmodal();
  }, [data, mode, setValue]);

  // Handle form submission for adding or editing a category
  const handleFormSubmit = async (formData) => {
    const objsection = new SectionClass();
    objsection.name = formData.name;
    objsection.shortName = formData.shortName;
    objsection.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}"; //userId
    objsection.Branch = branch;
    if (mode === "edit") {
      objsection.guid = data.guid;
      objsection.id = data.id;
    }

    try {
      const response =
        mode === "edit"
          ? await editSection(objsection)
          : await addNewSection(objsection);

      if (response) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        dispatch(fetchSection(branch.guid))
        handleClose(); // Close the modal
      }else if(response==false){
        toast.error("false")
       }
    } catch (error) {
      toast.error(error.response.data.Message || "something went wrong");
      console.error(
        "Error handling :",
        error.response.data.Message || "something went wrong"
      );
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{mode === "edit" ? "Edit Section" : "Add Section"}</h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Short Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Short Name"
                            {...register("shortName")}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode === "edit" ? "Save Changes" : "save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionModal;
