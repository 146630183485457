import React, { useEffect, useState } from "react";
import Table from "../../../../core/pagination/datatable";
import { useSelector } from "react-redux";


function EditProductSection({ register,getValues}) {


const tableData = getValues().sectionWiseProductDetailList;
  const { sectionList ,taxList} = useSelector((state) => state.usersetting);

  const columns = [
   
    {
      title: "Is Active",
      dataIndex: "",
      render: (_, value, index) => (
        <input type="checkbox" className=""
          {...register(`sectionWiseProductDetailList[${index}].IsActive`)} />
      )
    },
    {
      title: "Section",
      dataIndex: "name",
      render: (_, value, index) => (
        <div>
          <select name="" id="" className="form-control" 
            {...register(`sectionWiseProductDetailList[${index}].Section`)} >
            {sectionList.map((obj)=>{
              return(

                <option value={obj.guid} key={obj.id}>{obj.name}</option>
              )
            })}
             
          </select>
         
        </div>
      )
    },
    {
      title: "Price",
      dataIndex: "",
      render: (_, val, index) => (
        <div>
          <input type="number" className="form-control w-50"
            {...register(`sectionWiseProductDetailList[${index}].Price`)} />

        </div>
      )
    },

    {
      title: "Tax",
      dataIndex: "",

      render: (_, value, index) => (
        <div>
          <select name="" id="" className="form-control"
            {...register(`sectionWiseProductDetailList[${index}].Tax`)} >
            <option value=""> select</option>
            {taxList.map((obj) => {
              return (
                <option value={obj.guid} key={obj.id}>{obj.name}</option>
              )
            })}
          </select>
        </div>
      )

    },
    {
      title: "Tax Inclusive",
      dataIndex: "",
      render: (_, value, index) => (
        <input type="checkbox" className=""
          {...register(`sectionWiseProductDetailList[${index}].IsTaxIncludedInPrice`)} />
      )
    },

  ];


  return (
    <div>
    
        <div className="table-responsive product-list">
          <Table columns={columns} dataSource={tableData} />
        </div>

        
     

    </div>
  )
}

export default EditProductSection