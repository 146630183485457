import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { GroupEntryClass } from "../../../core/json/GroupEntry";
import {
  editGroupEntry,
  addGroupEntry,
} from "../../../services/MasterApiServices";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroup } from "../../../redux/userSetting";

const GroupEntryModal = ({ mode, data, handleClose, handleRefresh }) => {
  const { branch } = useSelector((state) => state.branchlist);
  const dispatch = useDispatch();
  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  const { register, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function resetmodal() {
    if (mode === "edit" && data) {
      setValue("name", data.name);
    } else {
      reset();
    }
  }

  useEffect(() => {
    resetmodal();
  }, [data, mode, setValue]);

  const handleFormSubmit = async (formData) => {
    const objGroup = new GroupEntryClass();
    objGroup.name = formData.name;
    objGroup.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}";
    objGroup.branch = branch;
    if (mode === "edit") {
      objGroup.guid = data.guid;
      objGroup.id = data.id;
    }

    try {
      const response =
        mode === "edit"
          ? await editGroupEntry(objGroup)
          : await addGroupEntry(objGroup);

      if (response) {
        toast.success("Success");
        handleRefresh();
        handleClose();
        dispatch(fetchGroup(branch.guid));
      }else if(response==false){
        toast.error("false")
       }
      
    } catch (error) {
      toast.error(error.response.data.Message || "something went wrong");
      console.error(
        "Error handling group:",
        error.response.data.Message || "something went wrong"
      );
    }
  };

  return (
    <div>
      <div className="modal fade show" style={{ display: "block" }} tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{mode === "edit" ? "Edit Group" : "Add Group"}</h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name")}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode === "edit" ? "Save Changes" : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupEntryModal;
