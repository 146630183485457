import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  RotateCcw,
  PlusCircle,
  ChevronUp,
  Send,
} from "feather-icons-react/build/IconComponents";
 
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { Pagination } from "antd";
import Selectbranch from "../../components/master/modal/Selectbranch";
import {
  deleteProduct,
  getProductList,
} from "../../services/MasterApiServices";
import { ProductClass } from "../../core/json/ProductClass";
import {
  fetchCategory,
  fetchGroup,
  fetchProduct,
  fetchSection,
  fetchTax,
  fetchType,
  fetchUnit,
} from "../../redux/userSetting";
import AddProduct from "../../components/master/modal/AddProduct";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import EditProduct from "../../components/master/modal/Editproduct";
import {
  exportPDF,
  exportExcel,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import ProductBranchTransfer from "../../components/master/modal/Product/ProductBranchTransfer";
 
const Product = () => {
 
  const [searchText, setSearchText] = useState("");
  const [updateData, setUpdateData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const dispatch = useDispatch();
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { branch } = useSelector((state) => state.branchlist);
  const { productList} = useSelector((state) => state.usersetting);
  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);
 
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
 
  useEffect(() => {
    dispatch(fetchCategory(branch.guid));
    dispatch(fetchTax());
    dispatch(fetchSection(branch.guid));
    dispatch(fetchType())
    dispatch(fetchGroup(branch.guid))
    dispatch(fetchUnit())
    dispatch(fetchProduct(branch))
  }, [branch]);

 

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = productList?.filter(
    (value) =>
      value.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
      value.code?.toLowerCase().includes(searchText?.toLowerCase())
  );
 
 
 
  const handleEditClick = async (guid) => {
    try {
      const SingleGroup = getProductGuid(guid);
      setUpdateData(SingleGroup); // Set the data for editing
 
    } catch (error) {
      console.error("Error fetching promotion GUID:", error);
    }
  };
  // Function to get a single object from filtering a list of group objects
  function getProductGuid(guid) {
    const filteredData = productList.filter((group) => group.guid === guid);
    return filteredData[0];
  }
  // Initialize SweetAlert with ReactContent
 
  const MySwal = withReactContent(Swal);
  // Handle the click event to delete a group
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objproduct = new ProductClass();
          objproduct.id = obj.id;
          objproduct.branch = branch;
          objproduct.guid = obj.guid;
          objproduct.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}";
          const response = await deleteProduct(objproduct);
          console.log(objproduct);
 
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The product has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              dispatch(fetchProduct(branch)); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting group:", error);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong.",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
    },
    {
      title: "Description",
      dataIndex: "otherDescription",
    },
    {
      title: "Action",
      key: "action",
 
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              data-bs-toggle="modal"
              data-bs-target="#edit-product"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
 
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Product</h4>
                <h6>Manage Product here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportPDF(productList, "Products")}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link onClick={() => exportExcel(productList, "Products")}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link onClick={() => HandlePrint(productList, "Products")}>
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => dispatch(fetchProduct(branch))}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <ul className="table-top-head"></ul>
 
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  data-bs-target="#product-branch-trans"
                  data-bs-toggle="modal"
                >
                  <Send className="me-2" />
                  Branch Transfer
                </Link>
              </div>
              <div className="d-flex purchase-pg-btn">
                <div className="page-btn">
                  <Link
                    to="#"
                    className="btn btn-added"
                    onClick={() => {
                      setModalMode("add"); // Set the modal mode to 'add'
                      setModalOpen(true); // Open the modal
                    }}
                  >
                    <PlusCircle className="me-2" />
                    New
                  </Link>
                </div>
              </div>
            </div>
          </div>
 
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
             
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Pagination />
      {updateData && <EditProduct data={updateData} />}
      <Selectbranch />
      {modalOpen && <AddProduct handleClose={() => setModalOpen(false)} />}
      <ProductBranchTransfer />
      {/* <AddProduct handleClose={() => setModalOpen(false)} /> */}
    </div>
  );
};
 
export default Product;
