import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Trash2 } from "feather-icons-react/build/IconComponents";

const EditProductProfile = ({ register, watch, control, errors }) => {


  const animatedComponents = makeAnimated();
  const [products, setproducts] = useState([])
  const { deliveyTypes, unitList, groupList, productList, categoryList } = useSelector((state) => state.usersetting);

  const comboType = deliveyTypes.find(type => type.name === 'Combo'); // Find Combo type
  const COMBO_TYPE_ID = comboType?.guid; // Get Combo type GUID

  useEffect(() => {
    const newlist = productList?.map(e => ({
      label: e.name,
      value: e.guid
    }))
    setproducts(newlist)
  }, [])
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });


  const handleAddMore = () => {
    append({ name: '', maxItems: '', comments: '' });
  };
  console.log(categoryList,"data");
  

  return (
    <div>
      <div className="modal-body custom-modal-body">
        <div className="branch-tabs col-12 align-items-center justify-content-between">

        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>Name<span className="text-danger">*</span></label>
              <input type="text" placeholder="Name"
                {...register("profile.name", {
                  required: "Name is required" 
                })}
              />
              {errors.profile?.name && (
                <span className="error text-danger text-sm">{errors.profile.name.message}</span>
              )}

            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>Short Name<span className="text-danger">*</span></label>
              <input type="text" placeholder="Short Name"  {...register("profile.shortName", { required: "Short Name is required" })} />
              {errors.profile?.shortName && (
                <span className="error text-danger text-sm">{errors.profile.shortName.message}</span>
              )}
            </div>
          </div>


          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>Code<span className="text-danger">*</span></label>
              <input type="text" placeholder="Code"  {...register("profile.code", { required: "Code is required" })} />
              {errors.profile?.code && (
                <span className="error text-danger text-sm">{errors.profile.code.message}</span>
              )}
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>BarCode <span className="text-danger">*</span></label>
              <input
                type="text"
                placeholder="Barcode"  {...register("profile.barCode", { required: "Barcode is required" })}
              />
               {errors.profile?.barCode && (
                <span className="error text-danger text-sm">{errors.profile.barCode.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>Category<span className="text-danger">*</span></label>
              <select
                {...register("profile.category", { required: "Category is required" })}

                className=" form-control"
                required
              >
                <option value="" disabled>
                  Select Category
                </option>
                {categoryList?.map((category) => (
                  <option key={category.guid} value={category.guid}>
                    {category.name}
                  </option>
                ))}
              </select>
              {errors.profile?.category && (
                <span className="error text-danger text-sm">{errors.profile.category.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>Item Type<span className="text-danger">*</span></label>
              <select
                {...register("profile.type", { required: "Item Type is required" })}
                className=" form-control"
                required
              >
                <option value="" disabled>Select Type</option>
                {deliveyTypes?.map((type) => (
                  <option key={type.guid} value={type.guid}>
                    {type.name}
                  </option>
                ))}
              </select>
              {errors.profile?.type && (
                <span className="error text-danger text-sm">{errors.profile.type.message}</span>
              )}
            </div>
          </div>
          {watch().profile?.type === COMBO_TYPE_ID &&
            <>
              {fields?.map((item, index) => (
                <div className="row border border-warning p-3" key={index}>

                  <div className="col-12">
                    <div className="input-blocks">
                      <label className="form-label">Select Combo Products</label>
                      <Controller
                        {...register(`items[${index}].name`,
                          { required: watch().profile?.type === COMBO_TYPE_ID }
                        )}
                        name={`items[${index}].name`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            closeMenuOnSelect={false}
                            {...field}
                            isMulti
                            components={animatedComponents}
                            placeholder="Select Combo Products"
                            // value={branches}
                            // onChange={handlebranches}
                            options={products}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label className="form-label">Enter maximum number of items</label>
                      <input
                        type="number"
                        min={0}
                        id={`items[${index}].maxItems`}
                        {...register(`items[${index}].maxItems`, { required: watch().profile?.type === COMBO_TYPE_ID })}
                        className="form-control"
                        placeholder='Enter maximum number of items'
                      />
                    </div>
                  </div>
                  {index > 0 &&
                    <div className="col-md-3 col-sm-6">
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="btn btn-danger"
                        style={{ height: "38px" }}
                      >
                        <Trash2 />
                      </button>

                    </div>
                  }

                </div>
              ))}
              <div className="text-end mt-3">
                <button type="button" onClick={handleAddMore} className="btn btn-primary">
                  Add More Side Choice
                </button>
              </div>


            </>
          } {/* Conditionally render AddCombo */}


        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>Group<span className="text-danger">*</span></label>
              <select
                {...register("profile.group", { required: "Group is required" })}

                className=" form-control"

              >
                <option value="" disabled>
                  Select Group
                </option>
                {groupList?.map((group) => (
                  <option key={group.guid} value={group.guid}>
                    {group.name}
                  </option>
                ))}
              </select>
              {errors.profile?.group && (
                <span className="error text-danger text-sm">{errors.profile.group.message}</span>
              )}
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>Base Unit<span className="text-danger">*</span></label>
              <select
                {...register("profile.unit", { required: "Base Unit is required" })}

                className=" form-control"

              >
                <option value="" disabled>
                  Select Unit
                </option>
                {unitList?.map((unit) => (
                  <option key={unit.guid} value={unit.guid}>
                    {unit.name}
                  </option>
                ))}
              </select>
              {errors.profile?.unit && (
                <span className="error text-danger text-sm">{errors.profile.unit.message}</span>
              )}
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>Cost<span className="text-danger">*</span></label>
              <input
                type="text"
                placeholder="COST"  {...register("profile.cost",{ required: "Cost is required" })}
              />
              {errors?.profile?.cost && (
                <span className="error text-danger text-sm">{errors?.profile?.cost?.message}</span>
              )}
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>BasePrice<span className="text-danger">*</span></label>
              <input
                type="text"
                placeholder="Baseprice"  {...register("profile.basePrice",{ required: "Base Price is required" })}
              />
              {errors?.profile?.basePrice && (
                <span className="error text-danger text-sm">{errors?.profile?.basePrice?.message}</span>
              )}
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="input-blocks">
              <label>Other Description</label>
              <input
                type="text"
                placeholder="Other Description"  {...register("profile.otherDescription")}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="input-blocks mt-4 d-flex">
              <input
                type="checkbox"
                placeholder="other discription"  {...register("profile.isActive")}
              />
              <label>Is Active</label>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default EditProductProfile

