import { DomainBase } from "./DomainBase";

export class InvStockAdjustmentDetail extends DomainBase {
  constructor() {
    super();
    this.product = null;
    this.currentCost = 0;
    this.baseQuantity = 0;
    this.adjQuantity = 0;
    this.unit = null;
    this.batch = "";
    this.addQty = 0;
    this.dedQty = 0;
  }
}
