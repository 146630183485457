import React, { useEffect, useRef, useState } from 'react'
import { getwebmenupermission, savewebmenupermission } from '../../../services/AdminApiServices'
import { useForm } from 'react-hook-form';
import { WebUserMenuPermission } from '../../../core/json/WebUserMenuPermission';
import toast from 'react-hot-toast';
import { Checkbox } from 'antd';
import { fetchMenuServices } from '../../../redux/menuServices';
import { useDispatch, useSelector } from 'react-redux';
 
const WebMenuPermission = ({ data }) => {
console.log(data);
 
  const { register, handleSubmit, setValue, getValues, watch } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { userId } =useSelector((state)=>state.userauth)
  const dispatch = useDispatch()
  useEffect(() => {
    if (data) {
      handleGetPermissions()
    }
  }, [data])
 console.log(userId);
 
  const handleGetPermissions = async () => {
 
    try {
      const response = await getwebmenupermission(data);
      const dashboard = response.menuPermissions.filter(e => e.menu.parentID == 1)
      const master = response.menuPermissions.filter(e => e.menu.parentID == 2)
      const transaction = response.menuPermissions.filter(e => e.menu.parentID == 3 && ![81,82,83,89].includes(e.menu.id))
      const admin = response.menuPermissions.filter(e => e.menu.parentID == 4)
      const reports = response.menuPermissions.filter(e => e.menu.parentID == 5 && ![6, 7, 8].includes(e.menu.id))
      const sales = response.menuPermissions.filter(e => e.menu.parentID == 6)
      const summary = response.menuPermissions.filter(e => e.menu.parentID == 7)
      const profit = response.menuPermissions.filter(e => e.menu.parentID == 8)
      const salestransaction = response.menuPermissions.filter(e => e.menu.parentID == 81)
      const purchase = response.menuPermissions.filter(e => e.menu.parentID == 82)
      const stock = response.menuPermissions.filter(e => e.menu.parentID == 83)
      const production = response.menuPermissions.filter(e => e.menu.parentID == 89)
      console.log(response);
     
      setValue('dashboard', dashboard)
      setValue('master', master)
      setValue('transaction', transaction)
      setValue('admin', admin)
      setValue('reports', reports)
      setValue('sales', sales)
      setValue('summary', summary)
      setValue('profit', profit)
      setValue('salestransaction', salestransaction)
      setValue('purchase', purchase)
      setValue('stock', stock)
      setValue('production', production)
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  }
  const closeButtonRef = useRef();
 
  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
const handlePermission=async()=>{
 
  const webObj = new WebUserMenuPermission()
  webObj.UserRole= data;
  webObj.MenuPermissions= [...watch().dashboard,...watch().master,...watch().transaction,...watch().admin,
    ...watch().reports,...watch().sales,...watch().summary,...watch().profit,...watch().salestransaction,
    ...watch().purchase,...watch().stock,...watch().production,]
 
  try {
    const response = await savewebmenupermission(webObj);
    if(response==true){
      toast.success("success")
      dispatch(fetchMenuServices({ id: userId.id }));
      closemodal()
      handleGetPermissions()
    }
   
  } catch (error) {
    console.error("Error fetching states:", error);
  }
}
console.log(watch().transaction, watch().reports,"hhhhh");
 
  return (
    <div>
 
      <div className="modal fade" id="web-menu">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Web Menu Permissions</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
               
                    <div className='form_body'>
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <div className="accordion-button"  data-bs-toggle="collapse" data-bs-target="#Dashboard" aria-expanded="true" aria-controls="Dashboard">
                              Dashboard
                            </div>
                          </h2>
                          <div id="Dashboard" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().dashboard?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`dashboard[${index}].view`)}checked={watch(`dashboard[${index}].view`)}onChange={(e) => setValue(`dashboard[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    {/* <Checkbox {...register(`dashboard[${index}].add`)}checked={watch(`dashboard[${index}].add`)}onChange={(e) => setValue(`dashboard[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`dashboard[${index}].edit`)}checked={watch(`dashboard[${index}].edit`)}onChange={(e) => setValue(`dashboard[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`dashboard[${index}].deletion`)}checked={watch(`dashboard[${index}].deletion`)}onChange={(e) => setValue(`dashboard[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label> */}
                                 
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#Masters" aria-expanded="false" aria-controls="Masters">
                              Masters
                            </div>
                          </h2>
                          <div id="Masters" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().master?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`master[${index}].view`)}checked={watch(`master[${index}].view`)}onChange={(e) => setValue(`master[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`master[${index}].add`)}checked={watch(`master[${index}].add`)}onChange={(e) => setValue(`master[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`master[${index}].edit`)}checked={watch(`master[${index}].edit`)}onChange={(e) => setValue(`master[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`master[${index}].deletion`)}checked={watch(`master[${index}].deletion`)}onChange={(e) => setValue(`master[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#Transactions" aria-expanded="false" aria-controls="Transactions">
                              Transactions
                            </div>
                          </h2>
                          <div id="Transactions" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().transaction?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`transaction[${index}].view`)}checked={watch(`transaction[${index}].view`)}onChange={(e) => setValue(`transaction[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`transaction[${index}].add`)}checked={watch(`transaction[${index}].add`)}onChange={(e) => setValue(`transaction[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`transaction[${index}].edit`)}checked={watch(`transaction[${index}].edit`)}onChange={(e) => setValue(`transaction[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`transaction[${index}].deletion`)}checked={watch(`transaction[${index}].deletion`)}onChange={(e) => setValue(`transaction[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingsalestrans">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#salestrans" aria-expanded="false" aria-controls="salestrans">
                             Sales
                            </div>
                          </h2>
                          <div id="salestrans" className="accordion-collapse collapse" aria-labelledby="headingsalestrans" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().salestransaction?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`salestransaction[${index}].view`)}checked={watch(`salestransaction[${index}].view`)}onChange={(e) => setValue(`salestransaction[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`salestransaction[${index}].add`)}checked={watch(`salestransaction[${index}].add`)}onChange={(e) => setValue(`salestransaction[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`salestransaction[${index}].edit`)}checked={watch(`salestransaction[${index}].edit`)}onChange={(e) => setValue(`salestransaction[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`salestransaction[${index}].deletion`)}checked={watch(`salestransaction[${index}].deletion`)}onChange={(e) => setValue(`salestransaction[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingpurchase">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#purchase" aria-expanded="false" aria-controls="purchase">
                             Purchase
                            </div>
                          </h2>
                          <div id="purchase" className="accordion-collapse collapse" aria-labelledby="headingpurchase" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().purchase?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`purchase[${index}].view`)}checked={watch(`purchase[${index}].view`)}onChange={(e) => setValue(`purchase[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`purchase[${index}].add`)}checked={watch(`purchase[${index}].add`)}onChange={(e) => setValue(`purchase[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`purchase[${index}].edit`)}checked={watch(`purchase[${index}].edit`)}onChange={(e) => setValue(`purchase[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`purchase[${index}].deletion`)}checked={watch(`purchase[${index}].deletion`)}onChange={(e) => setValue(`purchase[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingstock">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#stock" aria-expanded="false" aria-controls="stock">
                             Stock
                            </div>
                          </h2>
                          <div id="stock" className="accordion-collapse collapse" aria-labelledby="headingstock" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().stock?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`stock[${index}].view`)}checked={watch(`stock[${index}].view`)}onChange={(e) => setValue(`stock[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`stock[${index}].add`)}checked={watch(`stock[${index}].add`)}onChange={(e) => setValue(`stock[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`stock[${index}].edit`)}checked={watch(`stock[${index}].edit`)}onChange={(e) => setValue(`stock[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`stock[${index}].deletion`)}checked={watch(`stock[${index}].deletion`)}onChange={(e) => setValue(`stock[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingproduction">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#production" aria-expanded="false" aria-controls="production">
                             Production
                            </div>
                          </h2>
                          <div id="production" className="accordion-collapse collapse" aria-labelledby="headingproduction" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().production?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`production[${index}].view`)}checked={watch(`production[${index}].view`)}onChange={(e) => setValue(`production[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`production[${index}].add`)}checked={watch(`production[${index}].add`)}onChange={(e) => setValue(`production[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`production[${index}].edit`)}checked={watch(`production[${index}].edit`)}onChange={(e) => setValue(`production[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`production[${index}].deletion`)}checked={watch(`production[${index}].deletion`)}onChange={(e) => setValue(`production[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingAdministration">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#Administration" aria-expanded="false" aria-controls="Administration">
                              Administration
                            </div>
                          </h2>
                          <div id="Administration" className="accordion-collapse collapse" aria-labelledby="headingAdministration" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().admin?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`admin[${index}].view`)}checked={watch(`admin[${index}].view`)}onChange={(e) => setValue(`admin[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`admin[${index}].add`)}checked={watch(`admin[${index}].add`)}onChange={(e) => setValue(`admin[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`admin[${index}].edit`)}checked={watch(`admin[${index}].edit`)}onChange={(e) => setValue(`admin[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`admin[${index}].deletion`)}checked={watch(`admin[${index}].deletion`)}onChange={(e) => setValue(`admin[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingReports">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#Reports" aria-expanded="false" aria-controls="Reports">
                              Reports
                            </div>
                          </h2>
                          <div id="Reports" className="accordion-collapse collapse" aria-labelledby="headingReports" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                            {watch().reports?.map((obj, index) => {
                              console.log(obj.index);
                             
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`reports[${index}].view`)}checked={watch(`reports[${index}].view`)}onChange={(e) => setValue(`reports[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`reports[${index}].add`)}checked={watch(`reports[${index}].add`)}onChange={(e) => setValue(`reports[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`reports[${index}].edit`)}checked={watch(`reports[${index}].edit`)}onChange={(e) => setValue(`reports[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`reports[${index}].deletion`)}checked={watch(`reports[${index}].deletion`)}onChange={(e) => setValue(`reports[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                     
     
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingsales">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#Sales" aria-expanded="false" aria-controls="Sales">
                             Sales Reports
                            </div>
                          </h2>
                          <div id="Sales" className="accordion-collapse collapse" aria-labelledby="headingsales" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().sales?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`sales[${index}].view`)}checked={watch(`sales[${index}].view`)}onChange={(e) => setValue(`sales[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`sales[${index}].add`)}checked={watch(`sales[${index}].add`)}onChange={(e) => setValue(`sales[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`sales[${index}].edit`)}checked={watch(`sales[${index}].edit`)}onChange={(e) => setValue(`sales[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`sales[${index}].deletion`)}checked={watch(`sales[${index}].deletion`)}onChange={(e) => setValue(`sales[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingsummary">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#Summary" aria-expanded="false" aria-controls="Summary">
                              Summary Reports
                            </div>
                          </h2>
                          <div id="Summary" className="accordion-collapse collapse" aria-labelledby="headingsummary" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().summary?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`summary[${index}].view`)}checked={watch(`summary[${index}].view`)}onChange={(e) => setValue(`summary[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`summary[${index}].add`)}checked={watch(`summary[${index}].add`)}onChange={(e) => setValue(`summary[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`summary[${index}].edit`)}checked={watch(`summary[${index}].edit`)}onChange={(e) => setValue(`summary[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`summary[${index}].deletion`)}checked={watch(`summary[${index}].deletion`)}onChange={(e) => setValue(`summary[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingprofit">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#Profit" aria-expanded="false" aria-controls="Profit">
                             Profit Reports
                            </div>
                          </h2>
                          <div id="Profit" className="accordion-collapse collapse" aria-labelledby="headingprofit" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().profit?.map((obj, index) => {
                                return (
 
                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`profit[${index}].view`)}checked={watch(`profit[${index}].view`)}onChange={(e) => setValue(`profit[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`profit[${index}].add`)}checked={watch(`profit[${index}].add`)}onChange={(e) => setValue(`profit[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`profit[${index}].edit`)}checked={watch(`profit[${index}].edit`)}onChange={(e) => setValue(`profit[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`profit[${index}].deletion`)}checked={watch(`profit[${index}].deletion`)}onChange={(e) => setValue(`profit[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                       
 
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"                        
                         
                        >
                          Cancel
                        </button>
                        <button  className="btn btn-submit" onClick={handlePermission}>
                          Save
                        </button>
                      </div>
                    </div>
               
                </div>
 
 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default WebMenuPermission