import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  RefreshCw,
  PlusCircle,
  RotateCcw,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";
import Table from "../../core/pagination/datatable";
import ModifierModal from "../../components/master/modal/ModifierModal";
import {
  deleteModifier,
  getModifierByGuid,
  getModifierList,
  getModifierCategoryList,
} from "../../services/MasterApiServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";

const Modifier = () => {
  const [modifierData, setModifierData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // 'add' or 'edit'
  const [searchText, setSearchText] = useState("");

  // Initialize SweetAlert with ReactContent
  const MySwal = withReactContent(Swal);

  const { togglehead } = useSelector((state) => state.headerToggle);
  const dispatch = useDispatch();

  const renderTooltip = (message) => (
    <Tooltip id="pdf-tooltip">{message}</Tooltip>
  );

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  // Fetch the modifier list when the component mounts
  useEffect(() => {
    handleModifierList();
  }, []);

  // Fetch the list of modifier
  const handleModifierList = async () => {
    try {
      const modifierList = await getModifierList();
      const categoryList = await getModifierCategoryList();
      const mergedData = modifierList?.map((modifier) => {
        const filteredcategory = categoryList?.filter(
          (category) => category.guid === modifier.modifierCategory.guid
        );
        return {
          ...modifier,
          category: filteredcategory[0],
        };
      });
      setModifierData(mergedData);
    } catch (error) {
      console.error(
        "Error fetching modifier:",
        error.response.data.Message || "something went wrong"
      );
    }
  };

  // Handle the click event to edit a modifier ""
  const handleEditClick = async (guid) => {
    try {
      const response = await getModifierByGuid(guid);

      setUpdateData(response); // Set the data for editing
      setModalMode("edit"); // Set the modal mode to 'edit'
      setModalOpen(true); // Open the modal
    } catch (error) {
      console.error(
        "Error fetching modifier by GUID:",
        error.response.data.Message || "something went wrong"
      );
    }
  };

  // Handle the click event to delete a modifier ""
  const handleDeleteClick = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteModifier(id);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The modifier has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handleModifierList(); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting modifier :",
            error.response.data.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error.response.data.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  // Handle the search input change
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // Filter the modifier based on the search text
  const filteredData = modifierData
    ?.filter((entry) => {
      return Object.keys(entry).some((key) => {
        return String(entry[key])
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    })
    .sort((a, b) => b.createdAt - a.createdAt);

  // Columns for the data table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Category",
      dataIndex: "category.name",
      sorter: (a, b) => a.name.length - b.name.length,
      render: (_, record) => record.category.name, // Access nested object
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {/* Edit Button */}
            <Link
              className="me-2 p-2"
              to="#"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* Delete Button */}
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj.id)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Modifier </h4>
                <h6>Manage modifier here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() => exportPDF(modifierData, "Modifier Type")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(modifierData, "Modifier Type")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() => HandlePrint(modifierData, "Modifier Type")}
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleModifierList()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>

            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={filteredData} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modifier "" Modal */}
      {modalOpen && (
        <ModifierModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handleModifierList(); // Refresh the list
            setSearchText(""); // Clear the search text
          }}
        />
      )}
      {/* Modifier Modal */}
      {/* <ModifierModal
        open={modalOpen}
        mode={modalMode}
        onClose={() => setModalOpen(false)}
        data={modalMode === "edit" ? updateData : {}}
      /> */}
    </div>
  );
};

export default Modifier;
