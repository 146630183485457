import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  RefreshCw,
  RotateCcw,
  PlusCircle,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";
import Table from "../../core/pagination/datatable";
import CouponcodeModal from "../../components/master/modal/CoupenCodeModal";
import {
  deleteCouponCode,
  getCoupencodeByGuid,
  getCouponCodeList,
} from "../../services/MasterApiServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";

const Couponcode = () => {
  const [CouponcodeData, setCouponcodeData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // 'add' or 'edit'
  const [searchText, setSearchText] = useState("");
  const { togglehead } = useSelector((state) => state.headerToggle);
  const dispatch = useDispatch();

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  // Initialize SweetAlert with ReactContent
  const MySwal = withReactContent(Swal);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  // Fetch the Couponcode list when the component mounts
  useEffect(() => {
    handleCouponcodeList();
  }, []);

  // Fetch the list of Couponcode
  const handleCouponcodeList = async () => {
    try {
      const response = await getCouponCodeList();

      if (Array.isArray(response)) {
        setCouponcodeData(response);
      } else {
        setCouponcodeData([]); // If response is not an array, reset to an empty array
      }
    } catch (error) {
      console.log(error.response.data.Message);
      setCouponcodeData([]);
    }
  };

  // Handle the click event to edit a Couponcode ""
  const handleEditClick = async (guid) => {
    try {
      console.log(guid, "guid");

      const response = await getCoupencodeByGuid(guid);
      console.log(response);

      setUpdateData(response ? response.data : []); // Set the data for editing
      setModalMode("edit"); // Set the modal mode to 'edit'
      setModalOpen(true); // Open the modal
    } catch (error) {
      console.error(
        "Error fetching Couponcode by GUID:",
        error.response.data.Message
      );
    }
  };

  // Handle the click event to delete a Couponcode ""
  const handleDeleteClick = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteCouponCode(id);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The Coupon code has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handleCouponcodeList(); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting Couponcode:",
            error.response.data.Message
          );
          MySwal.fire({
            title: "Error!",
            text: error.response.data.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  // Handle the search input change
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = Array.isArray(CouponcodeData)
    ? CouponcodeData?.filter((value) =>
        value.couponName?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];

  // Filter the Couponcode based on the search text
  console.log(CouponcodeData);

  // const filteredData = CouponcodeData?.filter((entry) => {
  //     return Object.keys(entry).some((key) => {
  //       return String(entry[key])
  //         .toLowerCase()
  //         .includes(searchText.toLowerCase());
  //     });
  //   })
  //   .sort((a, b) => b.createdAt - a.createdAt);

  // Columns for the data table
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      sorter: (a, b) => a.code.length - b.code.length,
    },
    {
      title: "Coupon Name",
      dataIndex: "couponName",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Discount Percent",
      dataIndex: "discountPercent",
      sorter: (a, b) => a.discountPercent - b.discountPercent,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {/* Edit Button */}
            <Link
              className="me-2 p-2"
              to="#"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* Delete Button */}
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj.id)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Coupon Code</h4>
                <h6>Manage Coupon Code here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() => exportPDF(CouponcodeData, "Coupon List")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(CouponcodeData, "Coupon List")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={HandlePrint(CouponcodeData, "Coupon List")}
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleCouponcodeList}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>

            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Couponcode "" Modal */}
      {modalOpen && (
        <CouponcodeModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handleCouponcodeList(); // Refresh the list
            setSearchText(""); // Clear the search text
          }}
        />
      )}
    </div>
  );
};

export default Couponcode;
