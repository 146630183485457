import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import branchReducer from "./branchlist";
import authReducer from "./userdetails";
import companyReducer from "./companyDetail";
import menuReducer from "./menuServices";
import userServiceReducer from "./userServices";
import userBranchReducer from "./branchByUser";
import sectionsaleReducer from "./sectionwisesale";
import productsaleReducer from "./productwisesale";
import waitersaleReducer from "./waiterwisesale";
import dailysaleReducer from "./dailysale";
import initialReducer from "./initailvalues";
import top5saleReducer from "./topfivesale";
import least5saleReducer from "./leastfivesale";
import settingsReducer from "./settingbyBranch";
import userSettingReducer from "./userSetting";
import actionReducer from "./action";
import productReducer from "./productData";
import toggleReducer from "./toggle";
import supplierReducer from "./supplierData";
import getallReducer from "./getallmatser";

// Configuration for persisting Redux state
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "branchlist",
    "userauth",
    "companyInfo",
    "menuservice",
    "userservice",
    "userBranch",
    "sectionSale",
    "productsale",
    "waitersale",
    "dailysale",
    "initailslice",
    "top5sale",
    "least5sale",
    "settings",
    "usersetting",
    "action",
    "productdata",
    "headerToggle",
    "supplier",
    "getallmaster",
  ],
};

// Combining all reducers into a single persisted reducer
//slice mapped to reducers
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    branchlist: branchReducer,
    userauth: authReducer,
    companyInfo: companyReducer,
    menuservice: menuReducer,
    userservice: userServiceReducer,
    userBranch: userBranchReducer,
    sectionSale: sectionsaleReducer,
    productsale: productsaleReducer,
    waitersale: waitersaleReducer,
    dailysale: dailysaleReducer,
    initailslice: initialReducer,
    top5sale: top5saleReducer,
    least5sale: least5saleReducer,
    settings: settingsReducer,
    usersetting: userSettingReducer,
    action: actionReducer,
    productdata: productReducer,
    headerToggle: toggleReducer,
    supplier: supplierReducer,
    getallmaster: getallReducer,
  })
);

// Creating the Redux store with persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store); // Creating persistor linked to the store

export { store, persistor }; // Exporting store and persistor for use in the application
