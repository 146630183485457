import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
  Send
  
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import { fetchUserRole } from "../../redux/userSetting";
import AdminProductTable from "../../components/adminsitration/admintables/AdminProductTable";
import { fetchUser, fetchUserByBranch } from "../../redux/userServices";
import UserBranchTransfer from "../../components/adminsitration/UserBranchTransfer";
import AddUser from "../../components/adminsitration/users/AddUser";
import { deleteUser } from "../../services/AdminApiServices";
import { fetchBranchList } from "../../redux/branchlist";
import User from "../../core/json/User";


const UserAdmin = () => {

  const [searchText, setSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('add');
    const [updateData, setUpdateData] = useState([]);
    const {branch} = useSelector((state) => state.branchlist);
    const { userId } =useSelector((state)=>state.userauth)
  const dispatch = useDispatch()

  useEffect(() => {

  
    dispatch(fetchUserRole())
    dispatch(fetchBranchList())
  }, [])
  useEffect(() => {

    dispatch(fetchUserByBranch(branch.guid))

  }, [branch])

  
  const {togglehead} = useSelector((state) => state.headerToggle);
  const {UserListByBranch} = useSelector((state) => state.userservice);
  
  const handletogglehead=()=>{
    dispatch(setheadertoggle(!togglehead))
  }
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = UserListByBranch?.filter((value) =>
    value.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
    value.address1?.toLowerCase().includes(searchText?.toLowerCase()) ||
    value.address2?.toLowerCase().includes(searchText?.toLowerCase()) ||
    value.primaryContactNo?.toLowerCase().includes(searchText?.toLowerCase()) ||
    value.secondaryContactNo?.toLowerCase().includes(searchText?.toLowerCase()) ||
    value.email?.toLowerCase().includes(searchText?.toLowerCase()) ||
    value.userCode?.toLowerCase().includes(searchText?.toLowerCase()) 
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      
    },
    {
      title: "Address 1",
      dataIndex: "address1",
     
      
    },
    {
      title: "Address 2",
      dataIndex: "address2",
     
      
    },
    {
      title: "Primary Contact",
      dataIndex: "primaryContactNo",
 
    },
    {
      title: "Secondary Contact",
      dataIndex: "secondaryContactNo",
 
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "User Code",
      dataIndex: "userCode",
      sorter: (a, b) => a.userCode - b.userCode,
    },
    
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_,obj) => (
        <div className="action-table-data">
        <div className="edit-delete-action">
          <Link className="me-2 p-2" 
          to="#"
          onClick={() => handleEditClick(obj.guid)}
           >
            <i data-feather="edit" className="feather-edit"></i>
          </Link>
          <Link className="confirm-text p-2"
           to="#" 
           onClick={() => handleDeleteClick(obj.guid)}
            > 
            <i data-feather="trash-2" className="feather-trash-2"></i>
          </Link>
        </div>
      </div>
      ),
    },
];
const handleEditClick = async (id) => {

  setModalMode('edit'); // Set the modal mode to 'edit'
  setModalOpen(true); // Open the modal
  const data = UserListByBranch.find(e=>id== e.guid)
  setUpdateData(data)

};


  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
 
  const MySwal = withReactContent(Swal);

  const handleDeleteClick = (guid) => {
    const data = UserListByBranch.find(e=>guid== e.guid)
    const userObj = new User();
        userObj.AssignedBranch= data.assignedBranch;
        userObj.id = data.id;
        userObj.guid = data.guid;
        userObj.Name = data.name;
        userObj.PrimaryContactNo = data.contact1;
        userObj.SecondaryContactNo = data.contact2;
        userObj.Address1 = data.address1;
        userObj.Address2 = data.address2;
        userObj.Address3 = data.address3;
        userObj.UserRole = data.userRoles; // UserRole Class
        userObj.UserName = data.username;
        userObj.Password = data.password;
        userObj.Email = data.email;
        userObj.UserCode = data.usercode;  
        userObj.CanAccessWeb = data.weblogin;
        userObj.Branch = branch;
        userObj.updatedUser = `{${userId?.id}}`;
        userObj.createdUser = `{${userId?.id}}`;
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      console.log((result));
      if (result.isConfirmed) {
        try {
          const response = await deleteUser(userObj);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The User has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            })
            dispatch(fetchUser()) // Refresh the list
            
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting Reasontype:", error);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong.",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>User</h4>
            
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
                <div className="page-btn">
                  <Link
                    to="#"
                    className="btn btn-added"
                    data-bs-target="#user-branch-trans"
                    data-bs-toggle="modal"
                  >
                    <Send className="me-2" />
                   Branch Transfer
                  </Link>
                </div>
                <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  onClick={() => {
                    setModalMode('add'); // Set the modal mode to 'add'
                    setModalOpen(true); // Open the modal
                 
                  }}
                >
                  <PlusCircle className="me-2" />
                  Add New
                </Link>
              </div>
              </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              
                
              </div>
            
              <div className="table-responsive product-list">
                <AdminProductTable columns={columns} dataSource={modifiedList} />
               
              </div>
               
            </div>
          </div>
        
        </div>
      </div>
      <UserBranchTransfer/>
    
      {modalOpen && (
        <AddUser
          mode={modalMode}
          data={modalMode === 'edit' ? updateData : {}}
          handleClose={() => setModalOpen(false)}
         
        />
      )}
    </div>
  );
};

export default UserAdmin;
