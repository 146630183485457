import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCategoryListbybranch,
  getCounterListbyBranch,
  getCustomersList,
  getDiscountList,
  getGroupEntryListbybranch,
  getPaymentCardList,
  getProductList,
  getReasonList,
  getSectionListbybranch,
  getstates,
  getSupplierList,
  getTaxList,
  getTypeList,
  getUnitList,
} from "../services/MasterApiServices";
import { getUserRoleList } from "../services/AdminApiServices";
import { getSettings } from "../services/CoreApiServices";

export const fetchUserRole = createAsyncThunk(
  "filters/fetchUserRole",
  async () => {
    const data = await getUserRoleList();
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);
export const fetchSection = createAsyncThunk(
  "filters/fetchSection",
  async (branch) => {
    const data = await getSectionListbybranch(branch);
    console.log(data);

    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);

export const fetchType = createAsyncThunk("filters/fetchType", async () => {
  const data = await getTypeList();
  if (Array.isArray(data)) {
    return data;
  } else {
    return null;
  }
});
export const fetchStateList = createAsyncThunk(
  "filters/fetchStateList",
  async () => {
    const data = await getstates();
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);
export const fetchUnit = createAsyncThunk("filters/fetchUnit", async () => {
  const data = await getUnitList();
  if (Array.isArray(data)) {
    return data;
  } else {
    return null;
  }
});
export const fetchGroup = createAsyncThunk(
  "filters/fetchGroup",
  async (branch) => {
    const data = await getGroupEntryListbybranch(branch);
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);
export const fetchProduct = createAsyncThunk(
  'filters/fetchProduct',
  async (branch, { rejectWithValue }) => {
    try {
      const data = await getProductList(branch);
      if (Array.isArray(data)) {
        return data; // Resolve with product list
      } else {
        return rejectWithValue('Invalid data received');
      }
    } catch (error) {
      return rejectWithValue(error.message); // Handle potential errors
    }
  }
);
export const fetchCategory = createAsyncThunk(
  "filters/fetchCategory",
  async (branch) => {
    const data = await getCategoryListbybranch(branch);
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);
export const fetchCounter = createAsyncThunk(
  "filters/fetchCounter",
  async (branch) => {
    const data = await getCounterListbyBranch(branch);
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);
export const fetchTax = createAsyncThunk("filters/fetchTax", async () => {
  const data = await getTaxList();
  if (Array.isArray(data)) {
    return data;
  } else {
    return null;
  }
});
export const fetchReason = createAsyncThunk("filters/fetchReason", async () => {
  const data = await getReasonList();
  if (Array.isArray(data)) {
    return data;
  } else {
    return null;
  }
});
export const fetchPaymentType = createAsyncThunk(
  "filters/fetchPaymentType",
  async () => {
    const data = await getPaymentCardList();
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);
export const fetchDiscount = createAsyncThunk(
  "filters/fetchDiscount",
  async () => {
    const data = await getDiscountList();
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);
export const fetchCustomer = createAsyncThunk(
  "filters/fetchCustomer",
  async (branch) => {
    const data = await getCustomersList(branch);
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);
export const fetchSupplier = createAsyncThunk(
  "filters/fetchSupplier",
  async () => {
    const data = await getSupplierList();
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);
export const fetchSettings = createAsyncThunk(
  "filters/fetchSettings",
  async (branch) => {
    const data = await getSettings(branch);
    return data;
  }
);
const userSettings = createSlice({
  name: "usersetting",
  initialState: {
    reasonList: [],
    userRoles: [],
    deliveyTypes: [],
    stateList: [],
    sectionList: [],
    unitList: [],
    groupList: [],
    productList: [],
    formatedproductList:[],
    categoryList: [],
    counterList: [],
    taxList: [],
    customerList: [],
    formatedcustomerList: [],
    paymentTypeList: [],
    supplierList: [],
    discountList: [],
    loading: false,
    error: null,
    settingList:[],

  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserRole.fulfilled, (state, action) => {
        state.userRoles = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSection.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSection.fulfilled, (state, action) => {
        state.sectionList = action.payload;
        state.loading = false;
      })
      .addCase(fetchSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchType.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchType.fulfilled, (state, action) => {
        state.deliveyTypes = action.payload;
        state.loading = false;
      })
      .addCase(fetchType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchStateList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStateList.fulfilled, (state, action) => {
        state.stateList = action.payload;
        state.loading = false;
      })
      .addCase(fetchStateList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUnit.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUnit.fulfilled, (state, action) => {
        state.unitList = action.payload;
        state.loading = false;
      })
      .addCase(fetchUnit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroup.fulfilled, (state, action) => {
        state.groupList = action.payload;
        state.loading = false;
      })
      .addCase(fetchGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchProduct.pending, (state) => {
        state.loading = true; 
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.productList = action.payload;
        state.formatedproductList = action.payload?.map((product) => ({
          value: product.guid,
          label: product.name,
        }));
        state.loading = false; 
        state.error = null;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        state.categoryList = action.payload;
        state.loading = false;
      })
      .addCase(fetchCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCounter.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCounter.fulfilled, (state, action) => {
        state.counterList = action.payload;
        state.loading = false;
      })
      .addCase(fetchCounter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTax.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTax.fulfilled, (state, action) => {
        state.taxList = action.payload;
        state.loading = false;
      })
      .addCase(fetchTax.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchReason.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReason.fulfilled, (state, action) => {
        state.reasonList = action.payload;
        state.loading = false;
      })
      .addCase(fetchReason.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPaymentType.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPaymentType.fulfilled, (state, action) => {
        state.paymentTypeList = action.payload;
        state.loading = false;
      })
      .addCase(fetchPaymentType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDiscount.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDiscount.fulfilled, (state, action) => {
        state.discountList = action.payload;
        state.loading = false;
      })
      .addCase(fetchDiscount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.settingList = action.payload;
        state.loading = false;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.customerList = action.payload;
        state.formatedcustomerList = action.payload?.map((cus) => ({
          value: cus.guid,
          label: cus.name,
        }));
        state.loading = false;
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSupplier.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSupplier.fulfilled, (state, action) => {
        state.supplierList = action.payload;
        state.loading = false;
      })
      .addCase(fetchSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default userSettings.reducer;
