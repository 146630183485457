import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AdminTable from '../../components/adminsitration/admintables/AdminTable';
import { Settings} from "feather-icons-react/build/IconComponents";
import BranchSetting from '../../components/adminsitration/BranchSetting';
import { fetchBranchSettings } from '../../redux/settingbyBranch';
import { fetchSection, fetchStateList, fetchType, fetchUserRole } from '../../redux/userSetting';

const Branch = () => {

  useEffect(() => {
    dispatch(fetchUserRole())
    dispatch(fetchType())
    dispatch(fetchStateList())
    dispatch(fetchSection(branch.guid))
  }, [])
    const { branchlist,branch} = useSelector((state) => state.branchlist);
    console.log(branch);
    
    const [searchText, setSearchText] = useState('');
    const [branchId, setbranchId] = useState();
    const dispatch = useDispatch()
console.log(branchlist);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const modifiedList = branchlist.filter((value) =>
    value.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
  value.company?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
  value.address2?.toLowerCase().includes(searchText?.toLowerCase()) ||
  value.phone?.toLowerCase().includes(searchText?.toLowerCase()) ||
  value.email?.toLowerCase().includes(searchText?.toLowerCase()) ||
  value.website?.toLowerCase().includes(searchText?.toLowerCase()) 
  );

  
    const columns = [
      {
        title: "",
        dataIndex: "",
        render: (_, record) => (
          <div key={record.id}>
            <input type="checkbox" onChange={()=>handleSelectBranch(record.guid)} checked={record.guid==branchId} />
          </div>
        )
      },
      {
        title: "Name",
        dataIndex: "name",
        // onCell: (record) => ({
        //   onClick: () => handleSelectBranch(record.guid), // Handle the click event here
        // }),
    
      },
      {
        title: "Company",
        dataIndex: "",
        render: (_, record) => record.company.name
      },
      {
        title: "Address 1",
        dataIndex: "address1",
    
      },
      {
        title: "Address 2",
        dataIndex: "address2",
    
      },
      {
        title: "Phone",
        dataIndex: "phone",
    
      },
      {
        title: "Email",
        dataIndex: "email",
    
      },
  
    ];

 const handleSelectBranch=(id)=>{
  if(branchId==id){
    setbranchId()
  }else{

    setbranchId(id)
  }
 }  
 const handleGetSettings=(id)=>{
  dispatch(fetchBranchSettings(id))
 
  
 }  
  
  
   
  
    return (
      <div>
        <div className="page-wrapper">
          <div className="content">
          <div className="page-header transfer">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Branch</h4>
                  
                </div>
              </div>
              <ul className="table-top-head">
               
              </ul>
              <div className="d-flex purchase-pg-btn">
                <div className="page-btn">
                  <button
                    disabled={branchId? false: true}
                    className="btn btn-added"
                    data-bs-toggle="modal"
                  data-bs-target="#branch-setting"
                  onClick={()=>handleGetSettings(branchId)}
                   
                  >
                    <Settings className="me-2" />
                    Setting
                  </button>
                </div>
               
              </div>
            </div>
            
            <div className="card table-list-card">
              <div className="card-body">
                <div className="table-top">
                <div className="search-set">
                                <div className="search-input">
                                  <Link to="#" className="btn btn-searchset">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-search"
                                    >
                                      <circle cx={11} cy={11} r={8} />
                                      <line
                                        x1={21}
                                        y1={21}
                                        x2="16.65"
                                        y2="16.65"
                                      />
                                    </svg>
                                  </Link>
                                  <div
                                    id="DataTables_Table_0_filter"
                                    className="dataTables_filter"
                                  >
                                    <label>
                                      {" "}
                                      <input
                                        type="search"
                                        className="form-control form-control-sm"
                                        placeholder="Search"
                                        aria-controls="DataTables_Table_0"
                                        value={searchText}
                                        onChange={handleSearch}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                  
                </div>
               
                <div className="table-responsive product-list">
                  <AdminTable columns={columns} dataSource={modifiedList} handleSelect={handleSelectBranch} />
                </div>
              </div>
            </div>
          
          </div>
        </div>
        <BranchSetting/>
      </div>
    );
}

export default Branch
