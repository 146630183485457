import React, { useMemo } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import DashboardTable from './DashboardTable';

const SectionWiseSale = ({sectionSale,sectiondateSale}) => {
console.log(sectiondateSale);

  const seriesData = sectionSale?.map((item,i) => ({
    id: i,
    value: item?.percentage,
    label: item?.section?.name,
    // color: getRandomColor()
  }));

 const noData= [
    { id: 0, value: 360 ,color:"#c2c2c2" }
   
  ]
 // Extract unique sections
 const { sections, groupedData } = useMemo(() => {
  // Get unique sections
  const sections = [...new Set(sectiondateSale?.map((item) => item.section.name))];

  // Group by date
  const groupedData = sectiondateSale?.reduce((acc, item) => {
    const date = item.transactionDate.split("T")[0];
    if (!acc[date]) {
      acc[date] = { transactionDate: date, totals: {} };
    }
    // Initialize section total for the date if not already present
    if (!acc[date].totals[item.section.name]) {
      acc[date].totals[item.section.name] = 0;
    }
    // Add bill total to the section for the specific date
    acc[date].totals[item.section.name] += item.billTotal;
    return acc;
  }, {});

  return { sections, groupedData };
}, [sectiondateSale]);

// Prepare table columns
const columns = [
  {
    title: "Date",
    dataIndex: "transactionDate",
    key: "transactionDate",
    render: (date) => {
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date?.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
      };
      return <span>{formatDate(date)}</span>;
    },
  },
  ...sections.map((section) => ({
    title: section,
    dataIndex: section,
    key: section,
    render: (value) => (value !== undefined ? value : 0),
  })),
  {
    title: "Branch Total",
    key: "branchTotal",
    render: (_, record) => {
      // Calculate the total across all sections for a specific date
      const total = sections?.reduce((sum, section) => {
        return sum + (record[section] || 0);
      }, 0);
      return <strong>{total}</strong>;
    },
  },
];

// Prepare table data
const dataSource = groupedData
  ? Object.keys(groupedData).map((date) => {
      const row = { transactionDate: date };
      sections.forEach((section) => {
        row[section] = groupedData[date]?.totals?.[section] || 0;
      });
      return row;
    })
  : [];

  return (
    <div className="section-wise-sale">
      <div className="d-flex justify-content-center p-3">
        <PieChart
          series={[
            {
              data: sectionSale? seriesData : noData,
              innerRadius: 29,
              outerRadius: 150,
              paddingAngle: 1,
              cornerRadius: 5,
              startAngle: -360,
              endAngle: 180,
              cx: 80,
              cy: 150,
            },
          ]}
          width={400}
          height={400}
        />
      </div>
      <div className="d-flex justify-content-center">
        <div className="card flex-fill w-100 mb-4">
          <div className="card-body">
          <div className="table-responsive">
                  <div className="table-responsive product-list">
                  <DashboardTable columns={columns} dataSource={dataSource} />
                </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionWiseSale;
