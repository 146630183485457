import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import Table from "../../../core/pagination/datatable"
import { RefreshCw } from 'feather-icons-react/build/IconComponents';
import { Pagination } from 'antd';
import { getSalesReport } from '../../../services/ReportApiService';
function DateWiseProfit() {
  const [dateWiseProfitData, setDateWiseProfitData] = useState([]);
    useEffect(() => {
      handleSaleReportList()
    }, [])
    
    const handleSaleReportList = async ()  =>{
      try {
        const response = await getSalesReport();
        setDateWiseProfitData(response)
        console.log(response);
    } catch (error) {
        console.log(error);
    }
      }
   
   
    const columns = [
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => a.Name.length - b.Name.length,
      },
      {
        title: "Section",
        dataIndex: "name",
        sorter: (a, b) => a.Percentage.length - b.Percentage.length,
      },
      {
        title: "Counter",
        dataIndex: "counter",
        sorter: (a, b) => a.Percentage.length - b.Percentage.length,
      },
     
      {
      title: "Total Qty",
      dataIndex: "totalQty",
      sorter: (a, b) => a.Percentage.length - b.Percentage.length,
      },
      {
        title: "Total Cost Price",
        dataIndex: "totalCostPrice",
        sorter: (a, b) => a.Percentage.length - b.Percentage.length,
        },
        {
            title: "Total Discount",
            dataIndex: "totalDiscount",
            sorter: (a, b) => a.Percentage.length - b.Percentage.length,
            },
            {
                title: "Total Complimentory",
                dataIndex: "totalComplimentory",
                sorter: (a, b) => a.Percentage.length - b.Percentage.length,
                },
    
    ];
  
   return (
    <div>
        <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Date wise Profit</h4>
              </div>
            </div>
            <ul className="table-top-head">
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
               
              </div>
              <div className="page-btn import">
                <Link
                  to="#"
                  className="btn btn-added color"
                  data-bs-toggle="modal"
                  data-bs-target="#view-notes"
                >
                  <RefreshCw className="me-2" />
                  Refresh
                </Link>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
             
              {/* /Filter */}
              
              {/* /Filter */}
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={dateWiseProfitData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Pagination />
    </div>
  )
}

export default DateWiseProfit