import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setToogleHeader } from "../../core/redux/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import { deleteCategory } from "../../services/MasterApiServices";
import CategoryModal from "../../components/master/modal/CategoryModal";
import { CategoryClass } from "../../core/json/Category";
import { setheadertoggle } from "../../redux/toggle";
// import {
//   renderTooltip,
//   exportExcel,
//   exportPDF,
//   HandlePrint,
// } from "../../redux/helper.js";
import UserBranchTransfer from "../../components/adminsitration/UserBranchTransfer.jsx";
import CategoryBranchTransfer from "./MastersBranchTransfer/CategoryBranchTransfer.jsx";
import { fetchCategory } from "../../redux/userSetting.js";

const Category = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [updateData, setUpdateData] = useState([]);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { categoryList } = useSelector((state) => state.usersetting);
  //get branch
  const { branch } = useSelector((state) => state.branchlist);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  // Fetch the category list when the component mounts
  useEffect(() => {
    dispatch(fetchCategory(branch.guid));
  }, [branch]);
  const modifiedList = Array.isArray(categoryList)
    ? categoryList?.filter((value) =>
        value.name?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];

  // Handle the search
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // Handle the click event to edit a category

  const handleEditClick = async (guid) => {
    try {
      // const categoryList =response
      const SingleCategory = categoryList.find((e) => e.guid == guid);

      setUpdateData(SingleCategory); // Set the data for editing
      setModalMode("edit"); // Set the modal mode to 'edit'
      setModalOpen(true); // Open the modal
    } catch (error) {
      console.error(
        "Error fetching category by GUID:",
        error.response.data.Message
      );
    }
  };

  // Initialize SweetAlert with ReactContent
  const MySwal = withReactContent(Swal);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  // Handle the click event to delete a category
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objCategory = new CategoryClass();
          objCategory.id = obj.id;
          objCategory.guid = obj.guid;
          objCategory.branch = branch;
          console.log(branch, "branch is proper");
          objCategory.name = obj.name;
          objCategory.shortName = obj.shortName;
          objCategory.updatedUser = `{${userId?.id}}`;
          objCategory.createdUser = `{${userId?.id}}`;

          const response = await deleteCategory(objCategory);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The category has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              dispatch(fetchCategory(branch.guid)); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting category:",
            error.response.data.Message
          );
          MySwal.fire({
            title: "Error!",
            text: error.response.data.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      sorter: (a, b) => a.shortName.length - b.shortName.length,
    },
    {
      title: "Course",
      dataIndex: "course",
      sorter: (a, b) => a.course - b.course,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {/* Edit Button */}
            <Link
              className="me-2 p-2"
              to="#"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* Delete Button */}
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Category</h4>
                <h6>Manage categories here</h6>
              </div>
            </div>
            {/* <ul className="table-top-head">
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={() => renderTooltip("PDF")}
                >
                  <Link
                    onClick={() => exportPDF(categoryList, "Category List")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={() => renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(categoryList, "Category List")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={() => renderTooltip("Print")}
                >
                  <Link onClick={HandlePrint(categoryList, "Category List")}>
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => dispatch(fetchCategory(branch.guid))}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul> */}
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-target="#catagory-branch-trans"
                data-bs-toggle="modal"
              >
                Branch Transfer
              </Link>
            </div>

            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Category Modal */}
      <CategoryBranchTransfer />
      {modalOpen && (
        <CategoryModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() =>
            // Refresh the list
            setSearchText("")
          }
        />
      )}
    </div>
  );
};

export default Category;
//Uncaught TypeError: categoryData.filter is not a function
